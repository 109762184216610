.nav-selector {
  min-height: 26px;
  display: flex;
  background-color: white;
  color: black;
  letter-spacing: 0.3px;
  font-weight: 400;

  position: sticky;
  width: calc(100% - 1px);
  top: 0;
  z-index: 1; /* Ensure the NavSelector is above other content */
}
.dark-mode .nav-selector {
  background-color: rgba(255, 255, 255, 0);
  border-top-left-radius: 12px;
}

.nav-selector-files,
.nav-selector-analysis {
  flex: 1;
  display: flex;

  justify-content: center;
  align-items: center;
  text-align: center;

  border-bottom: 1px solid black;
  font-size: 13px;
  cursor: pointer;
}
.dark-mode .nav-selector-analysis {
  background-color: white;
}
.dark-mode .nav-selector-files {
  background-color: white;
}

.nav-selector-analysis.selected {
  background-color: black;
  color: #fff;
  cursor: default;
}
.nav-selector-files.selected {
  background-color: rgb(0, 0, 0);
  color: #fff;
  cursor: default;
}
.dark-mode .nav-selector-analysis.selected {
  color: #fff;
  cursor: default;
  background-color: rgb(0, 0, 0);
  border-bottom: None;
}
.dark-mode .nav-selector-files.selected {
  color: #fff;
  cursor: default;
  background-color: rgba(255, 255, 255, 0);
  border-bottom: None;
}

@media only screen and (max-width: 450px) {
  .nav-selector {
    display: none;
  }
}
