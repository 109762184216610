@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

.disclosure-analysis {
  background-color: white;
  padding: 32px 40px;
  font-family: "Outfit", sans-serif;
}

.disclosure-analysis-header {
  display: flex;
  justify-content: space-between;
}

.disclosure-analysis-viewers {
  display: flex;
  margin-top: 25px;
  height: calc(100vh - 265px);
}

.disclosure-analysis-navigation {
  border: 1px solid black;
  width: 290px;
  background-color: black;
  border-top-left-radius: 5px;
  color: white;
  overflow-y: auto;
  scrollbar-width: None;
}
::-webkit-scrollbar {
  display: none;
}

.disclosure-analysis-viewers-analysis {
  display: flex;
  flex: 7;
  margin-right: 15px;
  min-width: 800px;
  border-top-left-radius: 6px;
}

.disclosure-analysis-component-analysis {
  overflow-y: auto;
  max-width: calc(100% - 200px);
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  scrollbar-width: None;
}

/* Popup */
.disclosure-analysis-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.disclosure-analysis-popup-content {
  background-color: white;
  justify-content: center;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: max-content;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: "Outfit", sans-serif;
}

.disclosure-analysis-popup-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
}

/* Submit Button */
.disclosure-analysis-popup-button {
  font-size: 16px;
  padding: 10px 20px;
  background-color: #3fa36f;
  font-family: "Open Sans";
  color: white;
  font-weight: 550;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 150px;
  margin-top: 10px;
}

.disclosure-analysis-popup-button:hover {
  background-color: #48b779; /* Darken the button color on hover */
}

.disclosure-analysis-email-input {
  font-size: 16px;
  padding: 10px;
  margin-right: 10px;
  font-family: "Open Sans";
  border: 1px solid #7c7c7c;
  border-radius: 10px;
  width: 350px;
  padding-left: 14px;
  z-index: 1;
  text-align: center;
}

.disclosure-analysis-email-input::placeholder {
  color: rgb(176, 176, 176);
}
