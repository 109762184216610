.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-spinner__outer {
  height: 100px;
  width: 100px;
  background: conic-gradient(
    #dec880 0%,
    #ffffff 22%,
    #ff347d 68%,
    #827ded 100%
  );
  border-radius: 50%;
  animation: spin 1s ease infinite;
  mask: radial-gradient(closest-side, transparent 85%, rgba(0, 0, 0, 1) 70%); }

.loading-spinner__inner {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 90px;
  background-color: transparent;
  border-radius: 50%;
  z-index: 2;
}

.loading-spinner__inner img {
  width: 25px;
  height: auto;
}

/* Modifier: --larger for loading-spinner */
.loading-spinner--large .loading-spinner__outer {
  width: 150px;
  height: 150px;
}

.loading-spinner--large .loading-spinner__inner {
  width: 135px;
  height: 135px;
}

/* Modifier: --small for loading-spinner */
.loading-spinner--small .loading-spinner__outer {
  width: 60px;
  height: 60px;
}

.loading-spinner--small .loading-spinner__inner {
  width: 55px;
  height: 55px;
}
