.component-analysis-title {
  font-size: 34px;
  font-weight: 500;
}
.dark-mode .component-analysis-title {
  font-weight: 400;
}
@media only screen and (max-width: 450px) {
  .component-analysis-title {
    display: none;
  }
}
