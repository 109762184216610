.demo-disclosure-viewer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50%;
  margin-top: 125px;
  margin-bottom: 50px;
}

.demo-disclosure-viewer-video-container {
  position: relative;
  display: flex;
  margin-top: -85px;
  min-width: 50%;
  overflow: hidden;
}

.demo-disclosure-video-hover-label {
  position: absolute;
  right: calc(50% - 60px);
  top: calc(50% - 20px);

  min-width: 120px;
  min-height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.699);
  border-radius: 17.5px;

  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
  vertical-align: middle;
  white-space: nowrap;
  user-select: none;
}

/* Demo CSS */
.disclosure-viewer-demo-wrapper {
  min-height: 100vh;
  min-width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
}
.disclosure-viewer-demo-wrapper .darkened {
  position: absolute;
  z-index: 10;
}

.disclosure-viewer-demo {
  background: linear-gradient(to bottom, #000000 9%, #293139 65%);
  border-radius: 10px;
  padding: 20px;
  font-family: "Outfit", sans-serif;
  position: absolute;
  left: 0px;
  top: 0px;
  margin-left: 20px;
  margin-top: 80px;
  width: calc(100vw - 80px);

  z-index: 5;

  display: flex;
  flex-direction: column;
  opacity: 2;
  overflow: auto;
}

.demo-disclosure-close-demo-button {
  width: 20px;
  height: 20px;
  padding: 10px;
  border-radius: 5px;
  position: absolute;
  top: calc(20px);
  left: calc(20px);
  cursor: pointer;
  user-select: none;
}

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* MOBILE STYLES */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

@media screen and (max-width: 450px) {
  /* Demo CSS */
  .disclosure-viewer-demo-wrapper {
    position: fixed;
    min-width: 100vw;
    max-width: 100vw;
    background-color: rgba(0, 0, 0, 0.9);
    overflow: scroll;
  }
  .disclosure-viewer-demo-wrapper .darkened {
    min-width: 100vw;
    max-width: 100vw;
    z-index: 10;
    position: absolute;
    top: 0;
  }

  .demo-disclosure-close-demo-button {
    z-index: 10;
  }

  .disclosure-viewer-demo {
    margin-left: 0px;
    margin-top: 0px;
    padding: 0px;
    padding-top: 10vh;
    min-width: 100vw;
  }

  .demo-disclosure-viewer {
    position: relative;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
  }

  .disclosure-viewer-viewers {
    flex-direction: column;
    height: auto;
    margin-top: 15px;
  }

  .disclosure-viewer-navigation {
    min-width: 100%;

    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .disclosure-viewer-navigation.collapsed {
    height: 30px;
    overflow: hidden;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .disclosure-viewer-navigation.expanded {
    height: auto;
  }

  .disclosure-viewer-viewers-analysis {
    flex: 1;
    margin-right: 0;
    min-width: auto;
    width: 100%;
  }

  .disclosure-viewer-component-analysis {
    overflow-y: visible;
    max-width: 100%;
    width: 100%;
    height: 100%;
    margin-top: 0;
    background-color: rgba(255, 255, 255, 0.1);
  }

  .disclosure-viewer-viewers-analysis {
    flex-direction: column;
  }

  .demo-disclosure-viewer-video-container {
    position: relative;
    display: flex;
    min-width: 100%;
    max-width: 100%;
    align-self: center;
  }
}
