@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

/* ================================
   Page: Base Class
================================= */

.auth {
  background: linear-gradient(#000000 34%, #293139 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-family: "Outfit", sans-serif;
  color: white;
  min-height: 100vh;
}

/* ================================
     Title
================================= */

.auth__title {
  font-size: 45px;
  font-weight: 500;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 0;
}

/* ================================
     Form
================================= */

.auth__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  width: 70%;
  min-width: 650px;
  max-width: 800px;
  margin-top: 65px;
}

/* ================================
     Form Inputs
================================= */
/* 
  - PhoneInput: The whole container for the phone input component.

  - PhoneInputInput: The text input field within the PhoneInput component.

  - PhoneInputCountry: The image and drop down within the PhoneInput component

  - PhoneInputCountryIcon: The image of flag section

  - PhoneInputCountrySelectArrow: The drop down arrow of the options
*/

.auth__inputs {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
}

.PhoneInput {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PhoneInputCountryIcon {
  width: 40px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.PhoneInputCountryIcon:focus {
  outline: none;
  box-shadow: none;
}

.PhoneInputCountrySelectArrow {
  width: 10px;
  height: 10px;
}

.PhoneInputInput {
  margin-left: 10px;
}

.auth__input,
.PhoneInputInput {
  width: 100%;
  padding: 15px;
  border-radius: 8px;
  background: transparent;
  font-size: 20px;
  text-align: left;
  border: 1.5px solid rgba(255, 255, 255, 0.15);
  color: white;
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box;
}

.auth__input,
.PhoneInput {
  margin-bottom: 15px;
}

.auth__input:focus,
.PhoneInputInput:focus {
  outline: none;
  border: 2px solid rgba(255, 255, 255, 0.3);
}

.auth_input::placeholder,
.PhoneInputInput::placeholder {
  color: #9f9f9f;
}

/* ================================
     Error Message
================================= */

.auth__error-message {
  margin-top: 10px;
}

.auth__alert {
  font-size: 16px;
  margin: 0;
  color: #ff5757;
  display: flex;
  align-items: center;
}

.auth__error-icon {
  margin-right: 8px;
  width: 20px;
  height: auto;
}

/* ================================
     Button
================================= */

.auth__controls {
  margin-top: 10px;
}

.auth__button {
  font-family: "Open Sans", sans-serif;
  position: relative;
  padding: 10px 65px;
  background-color: transparent;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  z-index: 1;
  border: none;
  border-radius: 16px;
}

.auth__button::before {
  content: "";
  position: absolute;
  inset: 0;
  padding: 2px;
  border-radius: 14px;
  background: linear-gradient(
    90deg,
    #dec880 0%,
    #ffffff 22%,
    #ff347d 68%,
    #827ded 100%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  z-index: -1;
}

.auth__button--disabled {
  cursor: not-allowed;
}

.auth__button--disabled::before {
  background: linear-gradient(
    90deg,
    #dec8803d 0%,
    #ffffff3d 22%,
    #ff347d3d 68%,
    #827ded3d 100%
  );
}

/* ================================
     Links
================================= */

.auth__links {
  font-family: "Open Sans", sans-serif;
  color: #b8b8b8cc;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
}

.auth__action-link,
.auth__action-link a {
  color: white;
  font-weight: bold;
  text-decoration: none;
}

/* ================================
    Mobile Styles
================================= */
@media screen and (max-width: 450px) {
  /* Title */

  .auth__title {
    font-size: 36px;
  }

  /* Form */

  .auth__form {
    width: 85%;
    min-width: 250px;
    max-width: 400px;
    margin-top: 30px;
  }

  /* Inputs */
  .auth__inputs {
    margin-top: 40px;
    width: 85%;
  }

  .auth__input,
  .PhoneInputInput {
    font-size: 16px;
    padding: 10px;
  }

  .auth__input {
    margin-bottom: 15px;
  }

  .PhoneInputCountryIcon {
    width: 30px;
  }

  /* Error Message */

  .auth__error-icon {
    width: 15px;
  }
  .auth__password-alert {
    font-size: 14px;
  }

  /* Button */

  .auth__button {
    padding: 10px 30px;
    font-size: 14px;
    border-radius: 12px;
}
  
  .auth__button::before {
    padding: 1.5px;
    border-radius: 12px;
  }

  /* Links */

  .auth__links {
    font-size: 14px;
  }

  .auth__action-link,
  .auth__action-link a {
    font-weight: 600;
  }
}
