@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&family=Outfit:wght@500&display=swap");

.corresponding-files {
  width: 425px;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 40px;
  background: transparent;
}

.corresponding-files-toggle-button {
  background-color: rgb(0, 0, 0, 1);
  border: none;
  cursor: pointer;

  padding: 6px 6.5px;
  border-radius: 3px;
  text-align: left;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dark-mode .corresponding-files-toggle-button {
  background-color: rgb(0, 0, 0, 0.2);
}
.dark-mode .corresponding-files-toggle-button.open {
  background-color: rgb(0, 0, 0, 0.2);
  border-bottom: 1px solid rgb(0, 0, 0, 0.2);
}

.corresponding-files-toggle-button-text {
  color: white;
  font-size: 12.5px;
  font-family: "Outfit", sans-serif;
  font-weight: 420;
  letter-spacing: 0.45px;
}
.corresponding-files-toggle-arrow {
  margin-right: 2px;
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}
.corresponding-files-toggle-arrow.open {
  transform: rotate(-180deg);
}

.corresponding-files-dropdown {
  max-height: 0;
  overflow: hidden;
  border: none;
  margin-top: -5px;
}

.corresponding-files-dropdown.open {
  max-height: 300px;
  border-top: none;
  border: 1px solid black;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-top: -4px;
}
.dark-mode .corresponding-files-dropdown.open {
  border: 2px solid rgb(0, 0, 0, 0.2);
  border-top: none;
}

.corresponding-files-file-item {
  padding: 5px 0px;
  font-size: 13px;
  font-weight: 325;
  align-items: center;

  margin-right: 8px;
  margin-left: 6px;

  white-space: nowrap;
  overflow-x: auto;

  /* Hide scrollbar */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.container::-webkit-scrollbar {
  display: none; /* Hide scrollbar in webkit browsers */
}
.corresponding-files-file-item:first-child {
  margin-top: 4px;
}

.corresponding-files-file-item-divider {
  border-bottom: 1px solid black;
}
.corresponding-files-file-item-divider:last-child {
  border-bottom: none;
}

/* When no corresponding files */
.no-corresponding-files {
  margin-top: 10px;
  font-weight: 600;
  color: rgb(81, 81, 81);
}

/*Mobile styles */
@media only screen and (max-width: 450px) {
  .corresponding-files {
    width: 49vw;
    display: none;
  }
}
