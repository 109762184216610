/* ////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////// */
/* Form Style */
.faq-contact-mobile-form-container{
    display: none;
}

.faq-contact-form-container{
    display: flex;
    justify-content: space-between;
    background-color: rgba(255,255,255,0.1);
    padding: 32px; 
    border-radius: 20px;
    width: 100%;
    max-width: 800px;
    margin: auto;
}

/* ////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////// */
/* Header Content Style */
.faq-contact-form-content-left {
    flex: 1;
    padding-right: 32px;
}

.faq-contact-form-header {
    font-size: 50px;
    font-weight: 500;
    line-height: 1.2;
    color: #FFFFFF;
    margin-bottom: 16px;
}

.faq-contact-form-subtext {
    font-weight: 400;
    font-size: 20px;
    color: #9F9F9F
}

/* ////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////// */
/* Form Content Style */
.faq-contact-form-content-right {
    flex: 1;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 20px;
    padding: 12px; 
    display: flex;
    align-items: center;
}

.faq-contact-form {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: none;
    padding: 6; 
    width: 100%;
    flex: 1;
}

.faq-contact-form-input,
.faq-contact-form-textarea {
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 32px; 
    padding: 12px;
    font-size: 16px;
    border-radius: 10px;
    border: 1px solid;
    border-color: rgba(255, 255, 255, 0.15);
    background-color: transparent;
    color: #9F9F9F;
}

.faq-contact-form-input:focus,
.faq-contact-form-textarea:focus {
    outline: none;
    border-width: 1.5px;
    border-color: #ffffff4d;
}

.faq-contact-form-textarea {
    min-height: 200px; 
    resize: none;
}

/* ////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////// */
/* Message */


.contact-form-message__alert {
    font-size: 14px;
    margin: 0;
    color: #ffffff4d;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}



/* ////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////// */
/* Button Style */
.faq-contact-form-submit-button {
    position: relative;
    padding: 12px 16px; 
    font-size: 16px;
    background-color: transparent;
    color: #FFFFFF;
    cursor: pointer;
    align-self: flex-end;
    transition: background-color 0.3s;
    border: none;
    z-index: 1;
    margin-top: 4px; 
}

.faq-contact-form-submit-button::before {
    content: '';
    position: absolute;
    inset: 0;
    padding: 2px;
    border-radius: 8px;
    background: linear-gradient(
        90deg,
        #DEC880 0%,
        #FFFFFF 22%,
        #FF347D 58%,
        #827DED 100%
    );
    -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
}

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* MOBILE STYLES */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 450px) {
    .faq-contact-form-container{
        display: none;
    }

    .faq-contact-mobile-form-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 20px;
        padding: 20px;
        max-width: 300px;
        width: 100%;
        margin:auto;
    }

    /* ////////////////////////////////////////////////////////////////////////////////////// */
    /* ////////////////////////////////////////////////////////////////////////////////////// */
    /* Header Content Style */
    .faq-contact-form-mobile-top {
        display: flex;
        flex-direction: space-between;
        margin-bottom: 35px;
        align-self: flex-start;
    }

    .faq-contact-form-mobile-header {
        color: white;
        font-size: 22px;
        font-weight: 500;
        line-height: 1.2;
        max-width: 60%;
    }

    .faq-contact-form-mobile-subtext {
        color: #9F9F9F;
        font-size: 12px;
        font-weight: 400;
        max-width: 35%;
    }

    /* ////////////////////////////////////////////////////////////////////////////////////// */
    /* ////////////////////////////////////////////////////////////////////////////////////// */
    /* Form Content Style */
    .faq-contact-form-mobile-bottom{
        background-color: rgba(255, 255, 255, 0.05);
        border-radius: 20px;
        padding: 30px;
        padding-left: 40px;
        padding-right: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .faq-contact-form-mobile{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 300px;
    }

    .faq-contact-form-mobile-input,
    .faq-contact-form-mobile-textarea{
        font-family: 'Open Sans', sans-serif;
        margin-bottom: 10px;
        padding: 10px;
        font-size: 14px;
        color: #9F9F9F;
        border-radius: 5px;
        border: 1px solid;
        border-color: rgba(255, 255, 255, 0.15);
        background-color: transparent;
        width: 100%;
        outline: none;
    }

    .faq-contact-form-mobile-input:focus,
    .faq-contact-form-mobile-textarea:focus {
        outline: none;
        border-width: 1.5px;
        border-color: #ffffff4d;
    }

    .faq-contact-form-mobile-textarea {
        min-height: 200px; 
        resize: none;
    }

    .contact-form-message__alert {
        font-size: 12px;
        margin-bottom: 20px;
    }

    /* ////////////////////////////////////////////////////////////////////////////////////// */
    /* ////////////////////////////////////////////////////////////////////////////////////// */
    /* Button Style */
    .faq-contact-form-mobile-submit-button{
        position: relative;
        padding: 12px 16px;
        font-size: 12px;
        background-color: transparent;
        color: white;
        border: none;
        z-index: 1;
        align-self: flex-end;
    }

    .faq-contact-form-mobile-submit-button::before{
        content: '';
        position: absolute;
        inset: -2px;
        border-radius: 10px;
        background: linear-gradient(
            90deg,
            #DEC880 0%,
            #FFFFFF 22%,
            #FF347D 58%,
            #827DED 100%
        );
        -webkit-mask: 
            linear-gradient(#fff 0 0) content-box, 
            linear-gradient(#fff 0 0);
        mask: 
            linear-gradient(#fff 0 0) content-box, 
            linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        pointer-events: none;
        padding: 2px;
    }
}