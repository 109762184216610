.wrapper-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  background: linear-gradient(#000000 34%, #293139 100%);
}

.wrapper-loader__loading {
  font-size: 36px;
  font-family: "Outfit", sans-serif;
  color: white;
  text-align: center;
}
