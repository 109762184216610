.file-drop-zone {
  position: relative;
  z-index: 3;
  border: 1.5px solid #515151;
  border-radius: 15px;
  padding: 27px 0px 0px 0px;
  text-align: center;
  -webkit-transition: border 0.4s ease-in-out;
  -moz-transition: border 0.4s ease-in-out;
  -o-transition: border 0.4s ease-in-out;
  transition: border 0.4s ease-in-out;
  background-color: #030303;
  justify-content: center;
  align-items: center;
  height: 34vh;
  max-width: 40vw;
  margin: 0 auto;
  margin-top: 4vh;
  margin-bottom: 8vh;
}

.file-drop-zone.dragging {
  border: 1.5px solid transparent;
  position: relative;
  border-radius: 15px;
  z-index: 2;
}

.file-drop-zone::before {
  content: "";
  position: absolute;
  top: 0.5px;
  right: 0.5px;
  bottom: 0.5px;
  left: 0.5px;
  z-index: -1;
  background-color: black;
  border-radius: 15px;
}

.file-drop-zone::after {
  content: "";
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  background: linear-gradient(to left, #867ae9, #ff3b82, #fffbfd);
  border-radius: 15px;
  -webkit-transition: opacity 0.4s ease-in-out;
  -moz-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
  opacity: 0;
  z-index: -2;
}

.file-drop-zone.dragging::after {
  opacity: 1;
}

.disclosure-upload-zip-file-icon {
  height: 12vh;
  max-width: 20vw;
  margin-top: 1vh;
}

.disclosure-upload-drop-zone-instructions {
  color: white;
  margin-top: 1vh;
  font-size: 1.8vh;
  font-family: "Open Sans", sans-serif;
}

.disclosure-upload-select-file-label {
  display: inline-block;
  padding: 1vh 2vh;
  background-color: white;
  color: black;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1.5vh;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.disclosure-upload-select-file-label.dragging {
  background-color: white;
}

.disclosure-upload-select-file {
  display: none;
}

.disclosure-upload-select-file-label:active {
  background-image: linear-gradient(to left, #867ae9, #ff3b82, #fffbfd);
}

/* ================================
    Mobile Styles
================================= */
@media screen and (max-width: 450px) {
  .file-drop-zone {
    max-width: 80%;
    padding-left: 14px;
    padding-right: 14px;
  }

  .disclosure-upload-select-file-label {
    margin-top: 30px;
  }
}
