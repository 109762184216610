/* Condition level */
.condition {
  color: black;
}
.condition.EXCELLENT {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(to right, #6557e6, #e93d7c, #f170af);
}
.condition.GOOD {
  color: rgb(2, 96, 2);
}
.condition.FAIR {
  color: rgb(235, 159, 17);
}
.condition.POOR {
  color: rgb(220, 5, 5);
}
.condition.UNDETERMINABLE {
  color: grey;
}

/* Severity */
.severity-box {
  padding: 2px 5px;
  border-radius: 4px;
  width: max-content;

  font-size: 11px;
  font-weight: 450;
  letter-spacing: 0.25px;
  color: rgb(255, 255, 255);
  text-align: center;
}
.severity-box.MINOR {
  background-color: #3a3a3a;
}
.severity-box.MODERATE {
  background-color: #b761ba;
}
.severity-box.MAJOR {
  background-color: #e08929;
}
.severity-box.CRITICAL {
  background-color: #db2a2a;
}

/* Component */
.component-analysis-top-level-info {
  margin-bottom: 15px;
}
.component-analysis-top-level-info-item {
  font-weight: 600;
  font-size: 18px;
}

.component-analysis-section {
  border-radius: 3px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  border: 1.5px solid black;
  width: 100%;
}

/* Component Section Header */
.component-analysis-section-header {
  font-weight: 700;
  font-size: 18px;
  padding: 10px 0px;

  display: flex;
  justify-content: space-between;
  font-family: "Outfit" sans-serif;
  font-weight: 400;
}
.component-analysis-section-header-arrow {
  width: 13px;
  height: 13px;
  transition: transform 0.3s ease;
  margin: 0px 15px;
  margin-bottom: 0px;
  content: url("../../assets/SectionArrow-old.svg");
}
.component-analysis-section-header-arrow.expanded {
  transform: rotate(0deg);
}
.component-analysis-section-header-arrow.collapsed {
  transform: rotate(180deg);
}
.component-analysis-section-header-brief {
  margin-right: 15px;
}
.component-analysis-section-header-brief-condition-label {
  font-weight: 300;
}

/* Component Section Items */
.component-analysis-section-list {
  margin-left: 24px;
  margin-bottom: 8px;
  margin-right: 35px;
}
.component-analysis-section-item {
  margin-left: 10px;

  padding-left: 10px;
  padding-bottom: 16px;
  padding-top: 15px;

  line-height: 1.1;

  border-bottom: 1.5px solid rgba(0, 0, 0, 0.18);
}
.component-analysis-section-item.unindented {
  margin-left: 0px;
  padding-left: 0px;
}
.component-analysis-section-item:last-child {
  border-bottom: none;
}
.component-analysis-section-item.has-reference:hover {
  transition: background-color 0.25s ease;
  background-color: rgba(0, 0, 0, 0.044);
  border-radius: 4px;
  cursor: pointer;
}
.component-analysis-section-item.indented {
  border-left: 3px solid black;
}
.component-analysis-section-item-description {
  font-size: 16px;
  margin-bottom: 6px;
}
.component-analysis-section-item-recommendation {
  font-size: 16px;
  margin-bottom: 7px;
}
.component-analysis-section-item-description.bold {
  font-weight: 400;
}
.dark-mode .component-analysis-section-item-description.bold {
  font-weight: 350;
}
.component-analysis-section-item-extended-description {
  margin-top: 5px;
  margin-left: 5px;
  font-size: 12px;
}
.component-analysis-section-item-cost {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #c20202;
}

.component-analysis-section-item-additional-info-wrapper {
  margin-bottom: 10px;
  margin-top: 5px;
  font-size: 14px;
}

.component-analysis-section-item-additional-info-toggle {
  margin-top: 10px;
}

.component-analysis-section-item-additional-info {
  font-style: italic;
  margin-left: 20px;
  padding: 3px;
}
.component-analysis-section-item-additional-info.has-reference:hover {
  transition: background-color 0.15s ease;
  background-color: rgb(78, 83, 78);
  border-radius: 4px;
  cursor: pointer;
  width: fit-content;
}

.dark-mode .component-analysis-section-item-additional-info {
  margin-left: 20px;
}

.component-analysis-section-item-additional-info-arrow {
  content: url("../../assets/SectionArrow-old.svg");
  width: 12px;
  margin-right: 10px;
  transform: rotate(180deg);
}
.component-analysis-section-item-additional-info-arrow.expanded {
  transform: rotate(0deg);
}

.dark-mode .component-analysis-section-item-additional-info-arrow {
  content: url("../../assets/SectionArrow.svg");
  width: 14px;
}

/* Dark Mode Desktop Styles */
.dark-mode .condition {
  color: rgba(255, 255, 255, 0.75);
}

.dark-mode .condition.EXCELLENT {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(to right, #827ded, #ff3880, white);
}

.dark-mode .condition.GOOD {
  color: rgb(76, 175, 80); /* Slightly more vibrant green for dark mode */
}

.dark-mode .condition.FAIR {
  color: rgb(255, 193, 7); /* Vibrant yellow */
}

.dark-mode .condition.POOR {
  color: rgb(244, 67, 54); /* More vibrant red */
}

.dark-mode .condition.UNDETERMINABLE {
  color: grey;
}

/* Severity Box Dark Mode */
.dark-mode .severity-box {
  color: rgba(255, 255, 255, 0.75);
}

.dark-mode .severity-box.MINOR {
  background-color: #3a3a3a;
}

.dark-mode .severity-box.MODERATE {
  background-color: #b761ba;
}

.dark-mode .severity-box.MAJOR {
  background-color: #e08929;
}

.dark-mode .severity-box.CRITICAL {
  background-color: #db2a2a;
}

/* Component Dark Mode */
.dark-mode .component-analysis-top-level-info {
  color: rgba(255, 255, 255, 0.75);
}

.dark-mode .component-analysis-section {
  background: rgba(0, 0, 0, 0.31); /* Darker background for components */
  color: rgba(255, 255, 255, 0.75);
  border: none;
  border-radius: 7px;
}

.dark-mode .component-analysis-section-header {
  color: rgba(255, 255, 255, 0.75);
  font-weight: 300;
  background: transparent;
}

.dark-mode .component-analysis-section-header-arrow {
  content: url("../../assets/SectionArrow.svg");
}

.dark-mode .component-analysis-section-list {
  color: rgba(255, 255, 255, 0.75);
}

.dark-mode .component-analysis-section-item {
  border-bottom-color: rgba(255, 255, 255, 0.18);
}

.dark-mode .component-analysis-section-item:hover {
  background-color: rgba(255, 255, 255, 0.04);
}

.dark-mode .component-analysis-section-item.indented {
  border-left: 3px solid rgba(255, 255, 255, 0.18);
}

.dark-mode .component-analysis-section-item-description {
  color: rgba(255, 255, 255, 0.75);
}

.dark-mode .component-analysis-section-item-recommendation {
  color: rgba(255, 255, 255, 0.75);
}

.dark-mode .component-analysis-section-item-cost {
  color: #ff5252; /* Slightly more vibrant red */
}

/* Mobile-like dark styles applied for desktop */
.dark-mode .component-analysis-section-item-description.bold {
  color: rgba(255, 255, 255, 0.9);
}

.dark-mode .component-analysis-section-item-extended-description {
  color: rgba(255, 255, 255, 0.6);
}

/* Mobile styles */
@media only screen and (max-width: 450px) {
  .component-analysis-section-header {
    font-weight: 400;
    font-size: 18px;
    padding: 10px 0px;
    margin-left: 1vw;
    color: rgba(255, 255, 255, 0.75);

    display: flex;
    justify-content: space-between;
  }
  .component-analysis-section-item-description {
    font-size: 14px;
    color: #b8b8b8;
  }
  .component-analysis-section {
    background: #0000004f;
    color: rgba(255, 255, 255, 0.75);
    border: none;
    border-radius: 7px;
  }
  .component-analysis-section-header-arrow {
    width: 13px;
    height: 13px;
    transition: transform 0.3s ease;
    margin: 0px 15px;
    margin-bottom: 0px;
    content: url("../../assets/SectionArrow.svg");
  }
}
