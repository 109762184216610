.landing-mobile-agent-reviews {
  display: none;
}

@media screen and (max-width: 450px) {
  /* ////////////////////////////////////////////////////////////////////// */
  /* ////////////////////////////////////////////////////////////////////// */
  /* Agent review card */
  .landing-mobile-agent-reviews {
    margin-top: 40px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
  }

  .landing-mobile-agent-reviews-card {
    min-width: 325px;
    max-width: 325px;

    min-height: 150px;
    max-height: 150px;

    font-family: "Outfit", sans-serif;
    font-weight: 400;

    background-color: #f9fafb21;
    border-radius: 6px;

    color: #fff;
    padding: 20px 28px;
  }

  .landing-mobile-agent-reviews-card-message {
    white-space: normal;
    word-wrap: break-word;
    font-size: 15px;
    font-weight: 200;
    margin-top: 5px;
  }

  .landing-mobile-agent-reviews-card-profile {
    display: flex;
    align-items: center;
    margin-top: 45px;
  }

  .landing-mobile-agent-reviews-card-profile-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
  }

  .landing-mobile-agent-reviews-card-profile-info {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 10;
    margin-left: 2vw;
    color: #cacaca;
  }

  .landing-mobile-agent-reviews-card-profile-info strong {
    font-size: 16px;
    font-family: "Outfit", sans-serif;
    font-weight: 400;
    margin-bottom: 1vh;
    color: white;
  }

  .landing-mobile-agent-reviews-card-profile-info p {
    margin: 0;
    font-size: 3.5vw;
    color: #cacaca;
    font-family: "Outfit", sans-serif;
    font-weight: 500;
  }
  /* ////////////////////////////////////////////////////////////////////// */
  /* ////////////////////////////////////////////////////////////////////// */

  /* ////////////////////////////////////////////////////////////////////// */
  /* ////////////////////////////////////////////////////////////////////// */
  /* Lightbar styling */
  .landing-mobile-agent-reviews-lightbar-container {
    width: 100%;
    height: 7px;
    background-color: transparent; /* Background for the bar */
    position: relative;
    margin-top: 10px;
    border-radius: 2.5px;
    overflow: hidden; /* Ensures the lightbar stays within the container */
  }

  .landing-mobile-agent-reviews-lightbar {
    position: absolute;
    width: calc(100% / 5); /* Adjust according to the number of reviews */
    height: 100%;
    background: linear-gradient(90deg, transparent, #5c7086, transparent);
    border-radius: 2.5px;
    transition: transform 0.3s ease-in-out; /* Smooth transition */
  }
  /* ////////////////////////////////////////////////////////////////////// */
  /* ////////////////////////////////////////////////////////////////////// */
}
