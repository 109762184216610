@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&family=Outfit:wght@500&display=swap");

.hoa-financials-percent-funded {
  width: min-content;
}
.hoa-financials-percent-funded:hover {
  transition: background-color 0.25s ease;
  background-color: rgba(0, 0, 0, 0.083);
  border-radius: 4px;
  cursor: pointer;
}

.hoa-financials-rating {
  font-weight: 600;

  position: relative;
  display: inline-block;
  cursor: pointer;

  min-width: 100px;
}
.hoa-financials-rating.CAUTION {
  color: rgb(220, 5, 5);
}
.hoa-financials-rating.POOR {
  color: rgb(235, 159, 17);
}
.hoa-financials-rating.FAIR {
  color: #ef5fa5;
}
.hoa-financials-rating.ADEQUATE {
  color: rgb(44, 38, 231);
}
.hoa-financials-rating.GOOD {
  color: rgb(2, 96, 2);
}
.hoa-financials-rating.EXCELLENT {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(to right, #6557e6, #e93d7c, #f170af);
}

.hoa-financials-rating:hover .hoa-financials-rating-explanation {
  visibility: visible;
  opacity: 1;
}
.hoa-financials-rating-explanation {
  visibility: hidden;
  opacity: 0;
  background-color: white;

  top: 135%;
  left: 100%;
  z-index: 1;
  transform: translateX(-50%);

  width: max-content;
  border-radius: 5px;
  padding: 6px;
  position: absolute;

  font-size: 14px;
  color: black;
  font-weight: 400;

  border: 1px solid black;

  transition: opacity 0.3s;
}
.dark-mode .hoa-financials-rating-explanation {
  border: none;
}

.hoa-financials-rating-explanation::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 30%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}
