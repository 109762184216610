@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&family=Outfit:wght@500&display=swap");

/* Properties styling */
.general-inspection-properties-list {
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 44px;
  margin-right: 35px;

  padding-top: 9px;
  padding-left: 0px;
  padding-bottom: 6px;
  padding-right: 10px;

  border-bottom: 1.5px solid rgb(0, 0, 0);
  border-top: 1.5px solid rgb(0, 0, 0);
}
.dark-mode .general-inspection-properties-list {
  border-bottom: 0.5px solid white;
  border-top: 0.5px solid white;
}
.general-inspection-property {
  display: flex;

  margin-bottom: 5px;
  border-radius: 3px;
}
.general-inspection-property:hover {
  transition: background-color 0.25s ease;
  background-color: rgba(0, 0, 0, 0.044);
  cursor: pointer;
}
.general-inspection-property-name {
  font-weight: 600;
  min-width: 195px;
}
.general-inspection-property-value {
  font-weight: 400;
  min-width: 100px;
}

/* Mobile Styles */
@media screen and (max-width: 450px) {
  .general-inspection-properties-list {
    margin-left: 2.5%;
    margin-right: 2.5%;
    padding: 8px;
    width: 95%;
    box-sizing: border-box; /* Ensures padding is included in the width */
  }

  .general-inspection-property {
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start;
    padding: 8px; /* Add padding to each item */
    width: 100%;
  }

  .general-inspection-property-name {
    min-width: 0; /* Remove the minimum width to allow for flexible sizing */
    font-size: 14px; /* Adjust the font size for better readability on smaller screens */
    margin-bottom: 5px; /* Add some space between name and value */
  }

  .general-inspection-property-value {
    min-width: 0; /* Remove the minimum width to allow for flexible sizing */
    font-size: 14px; /* Adjust the font size for better readability on smaller screens */
  }
}
