@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Outfit:wght@100..900&display=swap");

.landing {
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden;
  width: 100%;
  background: linear-gradient(
    180deg,
    #000000 9%,
    #293139 25%,
    #000000 45%,
    #000000 52%,
    #293139 75%,
    #0000003d 100%
  );
  max-width: 100%;
}

.landing-page-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
}

.landing-home-graphic {
  position: absolute;
  left: calc(50% - 450px - 400px);
  top: -40px;
  width: 900px;
  height: 1000px;
  z-index: 0;
  opacity: 0.74;
  pointer-events: none;
}

/* ////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////// */
/* Common page styling */
.landing-section-banner {
  font-family: "Outfit", sans-serif;
  color: white;
  font-weight: 425;
  font-size: 48px;

  align-self: center;
  margin-top: 150px;
}
.landing-section-banner-subtext {
  margin-top: 19.5px;

  font-weight: 360;
  letter-spacing: 0.05px;
  font-family: "Open sans", sans-serif;
  font-size: 17.15px;
  color: #b8b8b8;
  text-align: center;
  text-justify: center;
}
/* ////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////// */

/* ////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////// */
/* Top Content (header and demo area) */
.landing-top-content {
  display: flex;
  flex-direction: row;

  z-index: 2;

  margin-top: 163px;
  width: 100%;
  max-width: 1700px;
}

/* Left content (header and buttons) */
.landing-top-content-left {
  color: white;
  text-align: left;
  flex-grow: 1;

  max-width: 45%;
  padding-left: 3.5%;
  margin-right: 0%;

  margin-top: -44px;
}
.landing-top-content-left-header {
  margin-top: 15px;
  font-size: 52px;
  letter-spacing: 0.75px;
  font-family: "Outfit", sans-serif;
  font-variant-alternates: stylistic(3);
  width: 460px;
  height: auto;
}
.landing-exclusive-banner {
  font-size: 15px;
  margin-bottom: 36px;
  text-align: left;
  margin-left: -32px;
  padding-left: 30px;
  background: linear-gradient(
    to right,
    #130f0d18 0%,
    #d9d9d933 9%,
    #d9d9d933 85%,
    #4b4b4b33 93.5%,
    #4b4b4b12 100%
  );
  font-family: "open sans", sans-serif;
}
.landing-top-content-left-header-image {
  width: 500px;
  max-width: 800px;
}
.landing-top-content-left-paragraph {
  font-size: 23px;
  font-weight: 315;
  letter-spacing: 0.6px;
  color: #cacaca;
  width: 600px;
}
.landing-top-content-left-paragraph-rainbow {
  margin-top: 1px;
  font-size: 39.5px;
  letter-spacing: 0.1px;
  font-weight: 665;

  background: linear-gradient(
    90deg,
    white 0%,
    white 30%,
    #ff3880 77%,
    #ff3880 82%,
    #ff3880 83%,
    #827dedd6 96%,
    #827dedd6 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.landing-top-content-left-buttons {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-top: 30px;
  margin-left: 5px;
  user-select: none;
  z-index: -2;
}
.landing-top-content-left-demo-button {
  font-size: 16.75px;
  letter-spacing: 0.7px;
  font-weight: 525;
  color: black;

  width: 148px;

  background-color: white;
  border-radius: 14px;

  padding: 14px 10px;
  margin-right: 15px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.landing-top-content-left-trial-button {
  font-size: 16.75px;
  letter-spacing: 0.65px;
  font-weight: 415;
  color: white;

  width: 148px;

  background-color: rgba(0, 0, 0, 0.051);
  border: 1px solid white;
  border-radius: 14px;

  padding: 14px 8px;
  margin-left: 15px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.landing-trial-button {
  font-size: 16.75px;
  letter-spacing: 0.65px;
  font-weight: 415;
  color: white;

  width: 148px;
  z-index: 1000;

  background-color: #13171b;
  border: 1px solid white;
  border-radius: 14px;

  padding: 14px 8px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: fixed;
  bottom: 30px;
  right: 30px;
}

/* Right content (demo) */
.landing-top-content-right {
  max-width: 56.5%;
  min-width: 56.5%;
  margin-left: -35px;
  max-height: 400px;
}

/* ////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////// */

/* ////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////// */
/* Trusted Agents */
.landing-agent-trust-banner {
  font-family: "Outfit", sans-serif;
  color: #cacaca;
  font-weight: 275;
  font-size: 15px;
  letter-spacing: 0.25px;

  align-self: center;
  text-align: center;
  text-justify: center;

  margin-bottom: 10px;
  margin-top: 200px;
}

.landing-brokerage-logos-container {
  display: flex;
  justify-content: space-around;
  position: relative;
  bottom: -25px;
}
.landing-brokerage-logo {
  margin: 0px 56px;
}
.landing-brokerage-logo.compass {
  width: 162px;
  margin-top: 3px;
}
.landing-brokerage-logo.coldwell {
  height: 33px;
  margin-top: 9px;
}
.landing-brokerage-logo.kw {
  height: 50px;
}
.landing-brokerage-logo.intero {
  width: 146px;
  margin-top: 4px;
}
.landing-brokerage-logo.sereno {
  width: 151px;
}
/* ////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////// */

/* ////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////// */
/* Benefits */
.landing-benefits-container {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  margin-top: 17.5px;
}

.landing-benefit {
  padding: 20px 10px;
  margin: 0 6px;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 224.5px;
  height: 275px;

  border: 1px solid #8879ec37;
  border-radius: 15px;
  background-color: #0c10123e;
}
.landing-benefit.mobile-slideshow-copy {
  display: none;
}
.landing-benefit-icon {
  width: 55px;
  height: auto;
}
.landing-benefit-title {
  font-family: "Outfit", sans-serif;
  font-weight: 385;
  color: white;
  font-size: 38px;
  line-height: 34px;

  margin: 22px 0px;
  max-height: 75px;
  min-height: 75px;

  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.landing-benefit-paragraph {
  color: #b8b8b8;
  font-size: 16.5px;
  font-weight: 260;
  font-family: "Outfit", sans-serif;

  line-height: 25px;
  margin: 0px 1.5px;
  min-height: 90px;

  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
/* ////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////// */

/* ////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////// */
/* Feature Exhibit */
.landing-features-exhibit {
  margin-top: 150px;
  display: flex;
  flex-direction: row;
  place-items: center;
  color: white;
  width: 1180px;
}

.landing-features-exhibit-title {
  font-family: "Outfit", sans-serif;
  font-weight: 415;
  font-size: 44px;
  min-width: 320px;
  max-width: 320px;
  margin-right: 92px;
}

.landing-features-exhibit-subtitle {
  font-size: 18.5px;
  color: #b8b8b8;
  margin-left: 20px;
  font-weight: 350;
}

.landing-features-exhibit-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 50px;
}

.landing-features-exhibit-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 382.5px;
  height: 360px;
  background-color: #8879ec08;
  border-radius: 20px;
  margin: 0px 7.5px;
}

.landing-features-exhibit-item:hover {
  background-color: #8879ec1a;
}

.landing-features-exhibit-item.expanded:hover {
  background-color: #8879ec08;
}

.landing-features-exhibit-item-label {
  color: white;
  font-family: "Outfit", sans-serif;
  font-size: 22px;
  font-weight: 400;

  min-height: 45px;
  width: calc(100% - 40px);
  margin: 17px 22px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.landing-features-exhibit-item-label-expand-button {
  cursor: pointer;
  width: 36px;
  height: 36px;
}

.landing-features-exhibit-item-label-expand-button:hover {
  background-color: #ffffff1a;
  border-radius: 50%;
}

.landing-features-exhibit-item-content {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.landing-features-exhibit-item-image {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

.landing-features-exhibit-item-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000b3;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.landing-features-exhibit-item-expanded {
  background: linear-gradient(#242a34, #13151b);
  border-radius: 20px;
  padding: 30px 30px;
  width: 600px;
  height: 575px;
  overflow-y: auto;
  position: relative;
}

.landing-features-exhibit-item-close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  border: 1px solid #717171;
  border-radius: 50%;
  padding: 5px;
  width: 15px;
  height: 15px;
  cursor: pointer;
  z-index: 2;
}

.landing-features-exhibit-item-close-icon:hover {
  background-color: #ffffff1a;
}

.landing-features-exhibit-item-expanded-content {
  flex-direction: column;
  align-items: center;
  margin: 0px 60px;
}

.landing-features-exhibit-item-expanded-image {
  width: 500px;
  height: 250px;
  margin-top: 20px;
  object-fit: cover;
  object-position: top;
}

.landing-features-exhibit-item-expanded-content h2 {
  color: white;
  font-family: "Outfit", sans-serif;
  font-size: 28px;
  margin-bottom: 20px;
  margin-left: 12px;
  margin-right: 12px;
  text-align: left;
}

.landing-features-exhibit-item-expanded-content p {
  color: #d6d6d6;
  font-family: "Outfit", sans-serif;
  font-size: 17px;
  font-weight: lighter;
  line-height: 1.6;
  margin-top: 30px;
  margin-bottom: 20px;
  margin-left: 12px;
  margin-right: 12px;
  white-space: pre-wrap;
}
/* ////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////// */

/* ////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////// */
/* DOCUMENTS WE ANALYZE */
.landing-documents-section {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;

  width: 1325px;
}
.landing-documents-banner {
  font-family: "Outfit", sans-serif;
  color: white;
  font-weight: 425;
  font-size: 48px;

  align-self: center;
  margin-top: 150px;
}
.landing-document-type-tabs-container {
  margin: 30px 0px;

  display: flex;
  text-align: center;
  align-items: center;

  width: fit-content;

  border-radius: 15px;
  border: 1px solid #8879ec3d;
}
.landing-document-type-tab {
  color: #b8b8b8;
  font-size: 15px;
  font-weight: 400;

  min-width: 100px;

  height: 30px;

  padding: 10px 26px;
  cursor: pointer;

  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.landing-document-type-tab.active {
  background-color: #8979ec;
  border-radius: 11px;
  color: white;
}
.landing-document-type-tab:first-child {
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px;
  padding-left: 31px;
}
.landing-document-type-tab:last-child {
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
  padding-right: 31px;
}

.landing-document-type-carousel-section {
  display: flex;
  flex-direction: column;
}
.landing-document-type-carousel-section-content {
  border-radius: 10px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.landing-document-type-carousel-section-item {
  border: 1px solid #8879ec34;
  border-radius: 10px;

  width: 650px;
  height: 614px;

  margin: 0 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.landing-document-type-carousel-section-item-header {
  font-family: "Outfit", sans-serif;
  font-weight: 200;
  color: white;
  font-size: 23px;
  margin-top: 16px;
  margin-bottom: 22px;
}
.landing-document-type-carousel-section-item-picture {
  min-height: 350px;
  max-height: 350px;
  border-radius: 10px;
  margin-bottom: 25px;
}
.landing-document-type-carousel-section-internal-divider {
  border-top: 1px solid #8879ec34;
  width: 100%;
}
.landing-document-type-carousel-section-bulleted-list-wrapper {
  width: 100%;
  background-color: #8879ec09;
  border-radius: 10px;

  min-height: 174px;
  max-height: 174px;

  display: flex;
  justify-content: center;
}
.landing-document-type-carousel-section-bulleted-list {
  min-width: 580px;
  max-width: 580px;
  margin-top: 24px;
  margin-bottom: 10px;

  min-height: 140px;
  max-height: 140px;

  display: flex;
  flex-direction: column;
  justify-content: center;
}
.landing-document-type-carousel-section-bulleted-list-item {
  width: 100%;
  min-height: 0px;
  max-height: 40px;
  margin-bottom: 17px;

  font-size: 16px;
  font-weight: 200;

  text-align: left;
  color: #b8b8b8;

  display: flex;
  align-content: center;
}
.landing-check-icon.circled {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  margin-top: -1px;
}

.landing-document-type-carousel-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.landing-document-type-carousel-button {
  border: none;
  border-radius: 19px;

  background-color: #343434;

  height: 34px;
  width: 34px;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
}
.landing-document-type-carousel-button:hover {
  background-color: #827ded;
}
.landing-document-type-carousel-button:disabled {
  background-color: rgba(255, 255, 255, 0.032);
  cursor: default;
}
.landing-document-type-carousel-button img {
  width: 12px;
  height: 12px;
}
.landing-document-type-carousel-button:disabled img {
  opacity: 0.2;
}
/* ////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////// */

/* ////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////// */
/* TESTIMONIALS */
.landing-testimonial-banner {
  font-family: "Outfit", sans-serif;
  color: white;
  font-weight: 425;
  font-size: 48px;

  align-self: center;
  margin-top: 150px;
}

.landing-testimonial-banner-subtext {
  margin-top: 19.5px;

  font-weight: 360;

  letter-spacing: 0.05px;
  font-size: 17.15px;
  color: #b8b8b8;
  text-align: center;
  text-justify: center;
}

.landing-agent-reviews-carousel-content {
  position: relative;
  overflow: hidden;

  margin-top: 30px;
  display: flex;
  justify-content: center;
  justify-items: center;
}

.landing-agent-review-card {
  background-color: rgba(255, 255, 255, 0.095);
  color: #fff;
  border-radius: 12px;
  padding: 25px;
  margin: 0 10px;
  width: 330px;
  height: 200px;
  display: inline-block;
  vertical-align: top;
  flex-shrink: 0;
}
.landing-agent-review-card-message {
  margin-top: 2px;
  white-space: normal;
  word-wrap: break-word;
  font-family: "Outfit", sans-serif;
  font-weight: 280;
  font-size: 20.5px;
  letter-spacing: 0.25px;
  line-height: 31px;
}
.landing-agent-review-card-profile {
  display: flex;
  align-items: center;
  margin-top: 40px;
}
.landing-agent-review-card-profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 13px;
  object-fit: cover;
}
.landing-agent-review-card-profile-info {
  display: flex;
  flex-direction: column;
}

.landing-agent-review-card-profile-info-name {
  font-size: 16px;
  font-family: "Outfit", sans-serif;
  font-weight: 400;
}

.landing-agent-review-card-profile-info-title {
  margin-top: 3px;
  font-size: 13px;
  width: 90%;
  color: #cacaca;
  font-family: "Outfit", sans-serif;
  font-weight: 300;
  line-height: 14.5px;
}
/* ////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////// */

/* ////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////// */
/* PLANS */
.landing-plans {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 1380px;
}

.landing-plans-banner {
  font-family: "Outfit", sans-serif;
  color: white;
  font-weight: 425;
  font-size: 48px;

  align-self: center;
  padding-top: 25px;
  margin-top: 135px;
}
.landing-plans-banner-subtext {
  margin-top: 19.5px;

  font-weight: 360;

  letter-spacing: 0.05px;
  font-size: 17.15px;
  color: #b8b8b8;
  text-align: center;
  text-justify: center;
}

.landing-plans-options {
  margin-top: 44px;

  width: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  justify-content: center;
  position: relative;
}
.landing-plans-option-gradient-edge-left {
  margin-top: 30px;

  width: 1px;
  min-height: 575px;

  background: linear-gradient(
    to bottom,
    #272e35 0%,
    #aca1f158 50%,
    #1e2529 100%
  );
}
.landing-plans-option-gradient-edge-right {
  margin-top: 30px;

  width: 1px;
  min-height: 575px;

  background: linear-gradient(
    to bottom,
    #272e35 0%,
    #aca1f158 50%,
    #1e2529 100%
  );
}
.landing-plans-option {
  min-width: 435px;
  max-width: 435px;

  display: flex;
  flex-direction: column;
  place-items: center;
  border-radius: 20px;
  margin-top: 38px;
}
.landing-plans-option-header {
  margin-top: 14px;

  width: 100%;

  font-family: "Outfit", sans-serif;
  font-weight: 500;
  font-size: 26px;
  letter-spacing: 1.8px;
  color: white;
}
.landing-plans-option-header-popular-tag {
  background-color: white;
  border-radius: 17px;
  padding: 5.5px 13px;
  position: absolute;
  right: calc(50% - 200px);
  margin-top: -38px;
  font-size: 14.7px;
  font-weight: 425;
  color: #6941c6f4;
  letter-spacing: 1.3px;
}
.landing-plans-option-header-subtext {
  font-weight: 350;
  color: #cacaca;
  font-size: 16px;
  font-family: "Open sans", sans-serif;

  margin-top: 6.5px;
  margin-bottom: 16px;

  text-align: center;
}
.landing-plans-option-internal-separator {
  border-top: 1px solid #8277cc32;
  width: 76%;
}
.landing-plans-option-header-price {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 49px;
  font-weight: 600;
  color: white;
  font-family: "Outfit", sans-serif;
}
.landing-plans-option-header-billing {
  font-size: 16.5px;
  font-weight: 300;
  margin-top: 1px;
  color: rgb(166, 166, 166);
}
.landing-plans-start-button {
  font-family: "Open sans", sans-serif;
  color: white;
  font-size: 16.5px;
  font-weight: 410;
  letter-spacing: 0.25px;
  cursor: pointer;

  width: 145px;
  height: 32px;

  margin-top: 20px;
  margin-bottom: 36px;
  padding: 10px;

  background-color: rgba(255, 255, 255, 0.063);
  border-radius: 15px;

  text-align: center;
  align-items: center;
  justify-content: center;

  transition: background-color 0.2s;
}
.landing-plans-start-button.purchase {
  display: block;
  height: 22px;
}
.landing-plans-start-button:hover {
  background-color: #f0f0f03f;
}
.landing-plans-option-benefits-list {
  margin-top: 25px;

  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.landing-plans-option-benefits-list-item {
  color: white;
  font-size: 15px;
  font-family: "Outfit", sans-serif;
  font-weight: 225;

  margin-bottom: 22px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.landing-plans-option-benefits-list-item-check {
  width: 10px;
  height: 10px;
  margin-right: 22px;
}

.landing-plans-option.pro {
  margin-top: 0px;
  min-width: 435px;
  max-width: 435px;

  border-radius: 18px;

  height: 620px;
  background: linear-gradient(
    159.5deg,
    #29313903 0%,
    #2931391a 9%,
    rgba(255, 255, 255, 0.201) 100%
  );
}
.landing-plans-option-header.pro {
  font-size: 38px;
  letter-spacing: 1.5px;
}
.landing-plans-option-header-subtext.pro {
  font-size: 17px;
  margin-bottom: 25.5px;
  margin-top: 3.5px;
  font-weight: 300;
  letter-spacing: 0.5px;
}
.landing-plans-option-internal-separator.pro {
  width: 90.5%;
}
.landing-plans-option-header-price.pro {
  margin-top: 27px;
}
.landing-plans-start-button.pro {
  background-color: white;
  color: black;
  font-weight: 515;
}
.landing-plans-start-button.pro:hover {
  background-color: rgb(109, 109, 109);
}
.landing-plans-option-benefits-list.pro {
  width: 83%;
}
.landing-plans-option-benefits-list-item.pro {
  font-size: 16.2px;
}
.landing-free-trial-message {
  text-align: center;
  color: #cacaca;
  font-family: "Open sans", sans-serif;
  margin-top: 70px;
  margin-bottom: 80px;
}
.landing-free-trial-message strong {
  color: #cacaca;
  font-weight: 600;
}
/* ////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////// */

/* ////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////// */
/* Marquee */
.landing-page-marquee {
  position: relative;
  margin-top: 80px;
  color: #e5e5e5;
  background: linear-gradient(
    to right,
    #13161a26 0%,
    #ffffff26 25%,
    #607c9826 50%,
    #ffffff26 75%,
    #13161a26 100%
  );
  overflow: hidden;
  padding-left: 60px;
  padding-right: 60px;
}

.landing-page-marquee-wrapper {
  display: flex;
  overflow: hidden;
  width: 100%;
  padding: 5px;
  white-space: nowrap;
  animation: marquee 500s linear infinite;
  position: relative; /* Ensure the pseudo-element is positioned relative to this */
}

.landing-page-marquee::after {
  content: ""; /* Required for pseudo-elements */
  position: absolute; /* Position it absolutely within the wrapper */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Ensure it does not block interactions */
  background: linear-gradient(
    to right,
    #100c0c 44.75%,
    transparent 46%,
    transparent 54%,
    #100c0c 55.25%
  );
  animation: none;
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 25%,
    rgba(0, 0, 0, 1) 75%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: 1; /* Ensure it's above the content */
}

.landing-page-marquee-content {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.marque-item {
  display: flex;
  align-items: center;
}

.marque-item li {
  list-style: none;
  margin-right: 10px;
  font-style: italic;
}

.seperator {
  font-style: normal;
}

@keyframes marquee {
  0% {
    transform: translateX(15%);
  }
  100% {
    transform: translateX(-15%);
  }
}
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* MOBILE STYLES */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

.landing-carousel-mobile-dots-container {
  display: none;
}

@media screen and (max-width: 450px) {
  .landing {
    background: linear-gradient(
      180deg,
      #000000 7%,
      #293139 25%,
      #000000 44%,
      #000000 2%,
      #293139 55%,
      #0000003d 100%
    );
  }
  /* ////////////////////////////////////////////////////////////////////// */
  /* ////////////////////////////////////////////////////////////////////// */
  /* Common page styling */
  .landing-section-banner {
    font-weight: 425;
    font-size: 25px;
    margin-top: 120px;
    margin-bottom: 5px;
  }
  .landing-section-banner-subtext {
    margin-top: 6px;
    font-family: "Outfit", sans-serif;

    font-weight: 300;
    letter-spacing: 0.05px;
    font-size: 14px;
    width: 95%;
    line-height: 15px;
  }

  /* ////////////////////////////////////////////////////////////////////// */
  /* ////////////////////////////////////////////////////////////////////// */

  /* ////////////////////////////////////////////////////////////////////// */
  /* ////////////////////////////////////////////////////////////////////// */
  /* Top Content (header and demo area) */
  .landing-top-content {
    flex-direction: column;
    margin-top: 45px;
  }
  .landing-home-graphic {
    top: 60px;
    left: -10px;
    width: 100%;
    opacity: 0.3;
  }

  .landing-top-content-left {
    margin: 0px 0px;
    place-content: center;
    text-align: center;
    min-width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    text-align: center;
    text-justify: center;
    left: 0px;
    right: 0px;
    padding-left: 0%;
  }
  .landing-top-content-left-header {
    font-size: 32px;
    text-align: center;
    text-justify: center;
    max-width: 100%;
  }

  .landing-top-content-left-paragraph {
    font-size: 16px;
    align-self: center;
    width: 375px;
    margin-top: 0px;
  }
  .landing-top-content-left-paragraph-rainbow {
    font-size: 24px;
    font-weight: 600;
    align-self: center;
  }
  .landing-top-content-left-buttons {
    justify-content: center;
    margin-top: 47.5px;
    width: 300px;
    z-index: 1;
  }
  .landing-top-content-left-demo-button {
    margin-right: 0px;
  }
  .landing-top-content-left-demo-button {
    border-radius: 17px;
    padding: 11px 0px;
    font-size: 15px;
    letter-spacing: 0.2px;
    width: 130px;
  }

  .landing-top-content-left-trial-button {
    border-radius: 17px;
    padding: 11px 0px;
    font-size: 15px;
    letter-spacing: 0.2px;
    width: 130px;
  }

  .landing-trial-button {
    width: 125px;
    font-size: 20px;
    height: 24px;
    bottom: 22px;
    right: 22px;
  }

  .landing-top-content-left-header-image {
    width: 340px;
  }
  .landing-top-content-right {
    min-width: 95%;
    max-width: 95%;
    height: auto;
    margin-top: 38%;
    margin-bottom: 10%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    align-self: center;
  }

  .demo-disclosure-viewer {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
  }

  .landing-exclusive-banner {
    width: 85vw;
    position: relative;
    margin-left: 0;
    left: 2.5vw;
    font-size: 35%;
    text-align: center;
    padding-left: 5.5vw;
    padding-right: 9vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
    margin-bottom: 25px;
  }
  /* ////////////////////////////////////////////////////////////////////// */
  /* ////////////////////////////////////////////////////////////////////// */

  /* ////////////////////////////////////////////////////////////////////// */
  /* ////////////////////////////////////////////////////////////////////// */
  /* Trusted Agents */
  .landing-agent-trust-banner {
    margin-top: 5vh;
    font-size: 10px;
    margin-bottom: 0px;
  }
  .landing-brokerage-logos-container {
    flex-direction: row;
    align-items: center;
    align-self: center;
    width: 93vw;
  }
  .landing-brokerage-logo {
    margin: 0px 7px;
  }
  .landing-brokerage-logo.compass {
    width: 80px;
    margin-top: 3px;
  }
  .landing-brokerage-logo.coldwell {
    height: 17px;
    margin-top: 1px;
  }
  .landing-brokerage-logo.kw {
    height: 25px;
  }
  .landing-brokerage-logo.intero {
    width: 70px;
    margin-top: 4px;
  }
  .landing-brokerage-logo.sereno {
    width: 70px;
  }

  /* ////////////////////////////////////////////////////////////////////// */
  /* ////////////////////////////////////////////////////////////////////// */

  /* ////////////////////////////////////////////////////////////////////// */
  /* ////////////////////////////////////////////////////////////////////// */
  /* Benefits */
  .landing-benefits-container {
    flex-direction: row;
    align-self: baseline;

    margin-top: 30px;

    min-width: calc((128px * 8) + (32px * 8) + 16px);
    max-width: calc((128px * 8) + (32px * 8) + 16px);

    animation: scroll-benefits 10s linear infinite;
  }
  @keyframes scroll-benefits {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translate(calc(-718px));
      /* You can keep  messing with this to get it right ^^ */
    }
  }

  .landing-benefit,
  .landing-benefit.mobile-slideshow-copy {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 0px 8px;
    padding-top: 20px;
    margin: 0 6px;

    min-height: 170px;
    max-height: 160px;

    min-width: 150px;
    max-width: 140px;

    border: 0.1px solid #8879ec1b;
    border-radius: 6px;
  }
  .landing-benefit-icon {
    width: 35px;
  }
  .landing-benefit-title {
    margin-top: 14px;
    margin-bottom: 18px;

    font-size: 19px;
    line-height: 16.25px;

    width: 90px;

    min-height: 0px;
  }
  .landing-benefit-paragraph {
    font-size: 14px;
    font-weight: 200;
    line-height: 15px;

    margin: 0px 0px;

    display: unset;

    min-height: 0px;
  }
  /* ////////////////////////////////////////////////////////////////////// */
  /* ////////////////////////////////////////////////////////////////////// */

  /* ////////////////////////////////////////////////////////////////////// */
  /* ////////////////////////////////////////////////////////////////////// */
  /* Feature Exhibit */
  .landing-features-exhibit {
    flex-direction: column;
    margin-top: 80px;
    max-width: 100%;
  }
  .landing-features-exhibit-title {
    font-size: 25px;
    font-weight: 425;
    min-width: 0px;
    max-width: 100%;
    margin-right: 0px;
  }
  .landing-features-exhibit-subtitle {
    margin-left: 0px;
    margin-top: 6px;
    max-width: 95%;
    padding: 0px 20px;

    font-size: 14.5px;
    font-family: "Outfit", sans-serif;
    font-weight: 300;
    line-height: 15px;
    text-align: center;
  }

  .landing-features-exhibit-container {
    margin-top: 40px;

    overflow-x: scroll;
    white-space: nowrap;

    width: 100%;
  }
  .landing-features-exhibit-container::-webkit-scrollbar {
    height: 8px;
    margin-top: 200px;
  }
  .landing-features-exhibit-container::-webkit-scrollbar-thumb {
    background-color: #b60b0b;
    border-radius: 4px;
  }
  .landing-features-exhibit-container::-webkit-scrollbar-thumb:hover {
    background-color: #cd1a1a;
  }
  .landing-features-exhibit-container::-webkit-scrollbar-track {
    background-color: #cd1a1a;
    border-radius: 4px;
  }

  .landing-features-exhibit-item {
    min-width: 175px;
    max-width: 175px;

    min-height: 220px;
    max-height: 220px;

    border-radius: 8px;

    margin: 0px 3px;
  }
  .landing-features-exhibit-item:first-child {
    margin-left: 7px;
  }
  .landing-features-exhibit-item:last-child {
    margin-right: 7px;
  }
  .landing-features-exhibit-item-label {
    font-size: 12px;
    letter-spacing: 0.1px;
    font-weight: 300;
    width: calc(100% - 19px);

    margin: 0;
    margin-left: 10px;
  }
  .landing-features-exhibit-item-label-expand-button {
    margin-top: 3px;
    width: 16.5px;
    height: 16.5px;
    display: none;
  }
  /* ////////////////////////////////////////////////////////////////////// */
  /* ////////////////////////////////////////////////////////////////////// */

  /* ////////////////////////////////////////////////////////////////////// */
  /* ////////////////////////////////////////////////////////////////////// */
  /* Documents We Analyze */

  .landing-documents-section {
    max-width: 100%;
    min-width: 100%;
  }

  .landing-documents-banner {
    font-family: "Outfit", sans-serif;
    color: white;
    font-weight: 425;
    font-size: 25px;
    margin-top: 95px;
    align-self: center;
  }

  .landing-document-type-tabs-container {
    flex-direction: row;
    border-radius: 5px;
    margin-top: 25px;
    margin-bottom: 20px;
    max-width: 400px;
  }

  .landing-document-type-tab {
    font-size: 9px;
    font-family: "Open", sans-serif;
    padding: 2px 10px;
    min-width: 50px;
    height: 25px;
  }

  .landing-document-type-tab.active {
    border-radius: 3px;
  }

  .landing-document-type-tab:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    padding-left: 10px;
  }
  .landing-document-type-tab:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    padding-right: 10px;
  }

  .landing-document-type-carousel-section {
    width: 100%;
  }

  .landing-document-type-carousel-section-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: 10px;
  }

  .landing-document-type-carousel-section-item {
    display: flex;
    flex-direction: row-reverse;

    background-color: #8879ec09;
    border: none;

    font-weight: 300;

    min-width: 410px;
    max-width: 410px;
    min-height: 250px;
    max-height: 250px;

    color: #b8b8b8;
    font-size: 10px;
    place-items: center;

    position: relative;
  }

  .landing-document-type-carousel-section-item-header {
    font-family: "Outfit", sans-serif;
    font-weight: 175;
    color: white;
    font-size: 14px;
    letter-spacing: 0.3px;

    margin: 0;
    position: absolute;
    top: 40px;
    left: 20px;
  }

  .landing-document-type-carousel-section-bulleted-list-wrapper {
    background-color: #00000000;

    margin: 0;
    position: absolute;
    top: 70px;
    left: 20px;

    min-height: fit-content;
    max-height: fit-content;

    justify-content: flex-start;
  }
  .landing-document-type-carousel-section-bulleted-list {
    margin-top: 0px;
    margin-bottom: 0px;

    min-width: 225px;
    max-width: 225px;

    min-height: min-content;
    max-height: min-content;
  }
  .landing-document-type-carousel-section-bulleted-list-item {
    display: flex;

    align-items: center;
    justify-content: left;
    align-self: left;
    align-content: left;

    min-width: 210px;
    max-width: 210px;

    font-size: 9px;
    color: #b8b8b8c6;

    margin-top: 0px;
    margin-bottom: 0px;

    padding: 0;
  }
  .landing-check-icon.circled {
    width: 12px;
    width: 12px;
    margin-top: 0px;

    align-self: center;
  }

  .landing-document-type-carousel-section-item-picture {
    position: absolute;
    right: 37px;
    top: 30px;

    margin-bottom: 0px;

    border-radius: 4px;

    min-height: 150px;
    max-height: 150px;
  }

  .landing-document-type-carousel-mobile-dots-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .landing-document-type-carousel-mobile-dot {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: #3e3e3e;
    border-radius: 50%;
    cursor: pointer;
  }
  .landing-document-type-carousel-mobile-dot.active {
    background-color: #595959;
  }

  .landing-document-type-carousel-section-internal-divider {
    display: none;
  }
  .landing-document-type-carousel-buttons {
    display: none;
  }
  /* ////////////////////////////////////////////////////////////////////// */
  /* ////////////////////////////////////////////////////////////////////// */

  /* ////////////////////////////////////////////////////////////////////////////////////// */
  /* ////////////////////////////////////////////////////////////////////////////////////// */
  /* TESTIMONIALS */
  .landing-agent-reviews {
    display: none;
  }

  .landing-testimonial-banner {
    font-weight: 425;
    font-size: 25px;
    margin-top: 75px;
    margin-bottom: 5px;
  }

  .landing-testimonial-banner-subtext {
    margin-top: 6px;
    font-family: "Outfit", sans-serif;

    font-weight: 300;
    letter-spacing: 0.05px;
    font-size: 15px;
    width: 95%;
    line-height: 14px;
  }
  /* ////////////////////////////////////////////////////////////////////// */
  /* ////////////////////////////////////////////////////////////////////// */

  /* ////////////////////////////////////////////////////////////////////////////////////// */
  /* ////////////////////////////////////////////////////////////////////////////////////// */
  /* PLANS */
  .landing-plans {
    width: 90%;
    margin-top: 25px;
  }

  .landing-plans-banner {
    font-weight: 425;
    font-size: 25px;
    margin-top: 60px;
    margin-bottom: 5px;
  }
  .landing-plans-banner-subtext {
    margin-top: 6px;
    font-family: "Open", sans-serif;

    font-weight: 300;
    letter-spacing: 0.05px;
    font-size: 14px;
    width: 315px;
    line-height: 16px;
  }

  .landing-plans-options {
    flex-direction: column;
    margin-top: 10px;
    width: 93vw;
  }

  .landing-plans-option-header {
    font-size: 17px;
    margin-top: 10px;
    margin-right: 3vw;
    margin-left: -10vw;
    margin-bottom: 15px;

    text-align: center;
  }
  .landing-plans-option-header.large {
    margin: 0;
  }
  .landing-plans-option-header.volume {
    margin-left: -7vw;
  }
  .landing-plans-option-header.basic {
    margin-left: -12vw;
  }
  .landing-plans-option-header.price {
    margin-left: -5vw;
  }

  .landing-plans-option-header-subtext,
  .landing-plans-subtext {
    font-size: 11px;
    border: none;
    margin-left: 10px;
    margin-top: 15px;
  }
  .landing-plans-option-header-subtext {
    width: 120%;
    text-align: right;
  }

  .landing-plans-subtext {
    width: 67vw;
  }
  .landing-plans-subtext.billing {
    margin-bottom: 10vh;
  }

  .landing-plans-option-internal-separator {
    display: none;
  }

  .landing-plans-option {
    transform: none !important;
    box-sizing: border-box;
    padding: 20px 30px;
    min-width: 90%;
    margin-top: 0px;
  }
  .landing-plans-option.pro {
    background: linear-gradient(
      180deg,
      #29313936,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    );
    border-radius: 15px;
    min-width: 100%;
    max-width: 100%;
    min-height: 350px;
    max-height: 350px;
  }
  .landing-plans-option-benefits-list {
    border: none;
    margin-bottom: 10px;
    width: 100%;
    margin-left: 15%;
  }
  .landing-plans-option-benefits-list-item {
    font-size: 13px;
    margin-bottom: 10px;
  }

  .landing-plans-option-benefits-list.pro {
    width: 100%;
  }

  .landing-plans-option-benefits-list-item.pro {
    font-size: 13px;
    margin-bottom: 10px;
    line-height: 100%;
  }

  .landing-plans-start-button {
    width: 50%;
    margin-bottom: 10px;
    background-color: #aaa1be27;
    border: none;
    color: white;
    margin-right: 15px;
    display: none;
  }
  .landing-plans-start-button.pro {
    background-color: white;
    color: black;
  }

  .popular-tag {
    font-size: 3vw;
    text-align: center;
    width: 25vw;
    height: 2vh;

    margin-right: 4vw;
  }

  .landing-plans-section {
    flex-direction: column;
    padding: 0;
  }

  .landing-check-icon {
    width: 2vw;
    height: 1vh;
    margin-right: 9vw;
  }

  .landing-plans-start-button {
    font-size: 3vw;
  }

  .landing-plans-option-header-wrapper {
    font-size: 0.75vw;
  }
  .landing-plans-option-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    align-items: flex-start;
  }

  .landing-plans-option-row-container {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #8879ec49;
    place-content: flex-start;
    text-align: left;
    text-justify: left;
    width: 100%;
    margin-left: 0;
  }

  .landing-faq-contact-border {
    display: none;
  }

  .landing-plans-option-gradient-edge-left {
    display: none;
  }
  .landing-plans-option-gradient-edge-right {
    display: none;
  }
  .landing-plans-option-header.pro {
    font-size: 20px;
  }
  .landing-plans-option-header-subtext.pro {
    margin-top: 15px;
    font-size: 12px;
  }
  .landing-plans-option-header-popular-tag {
    display: none;
  }

  .landing-plans-option-header-price {
    margin: 20px 20px;
    display: flex;
    flex-direction: row;

    font-size: 25px;
  }

  .landing-plans-option-header-billing {
    margin-left: 20px;
    margin-top: 9px;

    font-size: 12px;
  }
  /* ////////////////////////////////////////////////////////////////////////////////////// */
  /* ////////////////////////////////////////////////////////////////////////////////////// */

  /* ////////////////////////////////////////////////////////////////////////////////////// */
  /* ////////////////////////////////////////////////////////////////////////////////////// */
  /* Marquee */
  .landing-page-marquee {
    min-height: 80%;
    max-height: 80%;
    margin-top: 0px;
  }

  .landing-free-trial-message {
    margin-top: 50px;
    margin-bottom: 0px;
    font-size: 12px;
    font-weight: lighter;
  }
  .landing-free-trial-message strong {
    font-weight: 700;
  }

  .marque-item li {
    list-style: none;
    margin-right: 10px;
    font-style: italic;
    font-family: "Open sans", sans-serif;
    font-weight: lighter;
    font-size: 13px;
  }

  .seperator {
    font-style: normal;
  }
}
