@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

.navbar-dashboard-logout {
  font-family: "Outfit", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: white;
  border: 1px solid white; /* Black border to match the button background */

  margin-right: 15px;
  padding: 5px 15px;
  border-radius: 8px;

  cursor: pointer;
  user-select: none;
  display: none;
}

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* MOBILE STYLES */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

@media screen and (max-width: 450px) {
  .navbar-dashboard-logout {
    display: none;
  }
}
