.disclosure-analysis-shimmy {
  margin-top: 8px;
}

@keyframes shimmy {
  0% {
    background-color: hsl(180, 5%, 79%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

@keyframes shimmy-black {
  0% {
    background-color: hsl(180, 0%, 44%);
  }

  100% {
    background-color: hsl(0, 0%, 20%);
  }
}

/* Package Info */
.disclosure-analysis-shimmy-package-info-property-name {
  width: 500px;
  height: 42px;
  border-radius: 5px;
  opacity: 0.6;

  animation: shimmy 0.8s linear infinite alternate;
}
.disclosure-analysis-shimmy-package-info-directory {
  margin-top: 12px;

  width: 240px;
  height: 15px;
  border-radius: 5px;
  opacity: 0.6;

  animation: shimmy 0.8s linear infinite alternate;
}

/* Disclosure Viewer */
.disclosure-analysis-shimmy-disclosure-analysis-viewers {
  display: flex;
  margin-top: 24px;
  height: calc(100vh - 265px);
}

/* Nav and Component */
.disclosure-analysis-shimmy-disclosure-analysis-viewers-analysis {
  display: flex;
  flex: 7;
  margin-right: 15px;
  min-width: 600px;
  width: 750px;
}

/* Disclosure Analysis Naviation */
.disclosure-analysis-shimmy-disclosure-analysis-navigation {
  background-color: black;
  border: 1px solid black;

  cursor: none;

  min-width: 198px;

  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.disclosure-analysis-shimmy-disclosure-analysis-navigation::-webkit-scrollbar {
  display: none;
}
.disclosure-analysis-shimmy-disclosure-analysis-master-report {
  margin-top: 26px;
  margin-left: 16px;

  width: 155px;
  height: 20px;
  border-radius: 5px;
  opacity: 0.6;

  animation: shimmy-black 0.8s linear infinite alternate;
}
.disclosure-analysis-shimmy-disclosure-analysis-navigation-section-title {
  margin-top: 34px;
  margin-left: 16px;

  width: 50px;
  height: 8px;
  border-radius: 5px;
  opacity: 0.6;

  animation: shimmy-black 0.8s linear infinite alternate;
}
.disclosure-analysis-shimmy-disclosure-analysis-navigation-analysis-type {
  margin-top: 11px;
  margin-left: 16px;

  width: 155px;
  height: 16px;
  border-radius: 5px;
  opacity: 0.6;

  animation: shimmy-black 0.8s linear infinite alternate;
}

/* Disclosure Analysis Component */
.disclosure-analysis-shimmy-disclosure-component-analysis {
  max-width: calc(100% - 200px);
  width: 100%;
  height: 100%;

  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  animation: shimmy 0.8s linear infinite alternate;
}

/* PDF Viewer */
.disclosure-analysis-shimmy-pdf-viewer {
  flex: 3;
  border-radius: 1.3vh;
}

.disclosure-analysis-shimmy-pdf-viewer-iframe {
  width: 100%;
  height: calc(100%);
  border-radius: px;
  margin-bottom: 4px;
  border-radius: 1.3vh;

  animation: shimmy 0.8s linear infinite alternate;
}
