.faq-page {
    font-family: "Open Sans", sans-serif;
    overflow-x: hidden;
    background: linear-gradient(
        180deg,
        #000000 28%,
        #2b333c
    );
}

.faq-page-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.faq-list-mobile{
    display: none;
}
/* ////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////// */
/* faq header styling */
.faq-header {
    display: flex;
    flex-direction: column; 
    align-items: center; 
    padding: 20px; 
    text-align: center; 
}

.faq-header-img {
    margin-bottom: 20px; 
}

.faq-title {
    font-family: 'Outfit', sans-serif; 
    font-size: 48px; 
    font-weight: 500;
    color: #FFFFFF;
    margin-bottom: 10px; 
}

.faq-subtitle {
    font-family: 'Open Sans', sans-serif; 
    font-size: 25px;
    color: #9F9F9F;
}
/* ////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////// */
/* faq list styling */
.faq-list{
    width: 100%;
    max-width: 1000px;
    min-width: 1000px;
    margin: 0 auto;
    margin-top: 35px;
}

.faq-item {
    border-bottom: 1px solid #98A2B3;
    padding: 15px 0;
    cursor: pointer;
    color: #FFFFFF;
    font-weight: 600;
}

.faq-item:hover{
    color: #98a2b3dc;
}

.faq-question-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq-question{
    font-size: 1.3rem;
    margin-right: 15px; 
}

.faq-toggle-icon {
    font-size: 1.5rem;
    margin-left: 10px;
}

.faq-answer {
    padding-top: 10px;
    font-size: 16px;
    line-height: 1.5;
}
/* ////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////// */
/* faq contact form styling */
.faq-contact-form-page-container {
    margin-top: 100px; 
    min-width: 500px;
}
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* MOBILE STYLES */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 450px) {
    .faq-list{
        display: none;
    }
    /* ////////////////////////////////////////////////////////////////////////////////////// */
    /* ////////////////////////////////////////////////////////////////////////////////////// */
    /* faq header styling */
    .faq-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        text-align: center;
        margin-top: 45px;
    }

    .faq-header-img {
        margin-bottom: 20px;
        width: 30%;
        max-width: 30px;
    }

    .faq-title {
        font-size: 28px;
    }

    .faq-subtitle {
        font-size: 16px;
    }

    /* ////////////////////////////////////////////////////////////////////////////////////// */
    /* ////////////////////////////////////////////////////////////////////////////////////// */
    /* faq list styling */
    .faq-list-mobile {
        display:block;
        width: 100%;
        max-width: 330px;
        min-width: 330px;
        margin: 0 auto;
    }

    .faq-item-mobile {
        border-bottom: 1px solid #98A2B3;
        padding: 10px 0;
        cursor: none;
        color: white;
        font-weight: 600;
    }

    .faq-question-wrapper-mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .faq-question-mobile{
        font-size: 14px;
        margin-right: 10px;
    }

    .faq-question-icon{
        font-size: 14px;
        margin-left: 8px;
    }

    .faq-answer-mobile {
        padding-top: 8px;
        font-size: 12px;
        line-height: 1.5;
    }

    /* ////////////////////////////////////////////////////////////////////////////////////// */
    /* ////////////////////////////////////////////////////////////////////////////////////// */
    /* faq contact form styling */
    .faq-contact-form-page-container {
        margin-top: 50px; 
        margin-bottom: 50px; 
        min-width: 300px;
    }
}


