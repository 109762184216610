@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&family=Outfit:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Outfit:wght@300&display=swap");

.disclosure-upload-page-title {
  text-align: center;
  color: white;
  font-family: "Outfit", sans-serif;
  font-size: 10vh;
  margin-top: 6vh;
}

.disclosure-upload-page-subtitle {
  text-align: center;
  color: #cacaca;
  font-family: "Open Sans", sans-serif;
  font-size: 2vh;
  margin-top: 0.5vh;
  line-height: 2.5vh;
}

/* Spinner styling */
.spinner-container {
  position: relative;
  width: 120px;
  height: 120px;
  margin-top: 10%;
}

.progress-text {
  position: absolute;
  font-size: 1.5em;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  text-align: center;
  margin-left: 5px;
}

.spinner-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.large-spinner {
  position: absolute;
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #555; /* Dark grey */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  right: 10px;
  top: -55px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg); /* Only rotation */
  }
  100% {
    transform: rotate(360deg); /* Only rotation */
  }
}

@media screen and (max-width: 450px) {
  .disclosure-upload-page-title {
    font-size: 72px;
    margin-top: 80px;
    line-height: 70px;
    margin-bottom: 30px;
  }
}
