.master-report-title {
  font-size: 34px;
  font-weight: 500;
  width: 220px;

  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(to right, #6557e6, #e93d7c, #f170af);
}

.master-report-category {
  margin-bottom: 30px;
  margin-top: 15px;
}

.master-report-category-name {
  font-size: 21px;
  font-weight: 375;
  margin-bottom: 6px;
  width: 200px;

  color: rgb(168, 168, 168);
}
.dark-mode .master-report-category-name {
  color: rgba(255, 255, 255, 0.75);
  font-weight: 300;
}

.master-report-section-item {
  margin-left: 10px;
  margin-top: 6px;

  padding-left: 10px;
  margin-bottom: 4px;
  padding-top: 6px;
  padding-bottom: 6px;

  line-height: 1.1;
}

.master-report-section-item.has-reference:hover {
  transition: background-color 0.25s ease;
  background-color: rgba(0, 0, 0, 0.044);
  border-radius: 4px;
  cursor: pointer;
}
.master-report-section-item.indented {
  border-left: 3px solid black;
}
.master-report-section-item-description {
  font-size: 17px;
  font-weight: 350;
}
.dark-mode .master-report-section-item-description {
  font-size: 17px;
  font-weight: 225;
}
