@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

.package-info

/* Package Name */
.package-info-name {
  display: flex;
  align-items: baseline;
}

.package-info-property-name {
  font-family: "Outfit", sans-serif;
  font-size: 44px;
  font-weight: 500;
  max-width: 1000px;

  border: None;
  outline: none;
}

.package-info-property-name-edit {
  height: 16px;
}

/* Directory path */
.package-info-directory {
  display: flex;
  align-items: baseline;
  font-family: "Outfit", sans-serif;
  font-size: 15px;
}
.package-info-directory-disclosures {
  font-weight: 300;
  text-decoration: underline;
  color: #8979ec;
}

.package-info-directory-arrow {
  font-weight: 300;
  margin-left: 11px;
}

.package-info-directory-property-name {
  font-weight: 300;
  margin-left: 11px;
}

@media only screen and (max-width: 450px) {
  .package-info {
    padding-top: 0px;
  }
  .package-info-name {
    width: 90vw;
    background: transparent;
    color: white;
  }
  .package-info-property-name {
    width: 50vw;
    font-size: 22px;
    color: white;
    background: transparent;
    margin-left: 10px;
  }
  .package-info-property-name-edit {
    display: none;
  }
  .package-info-directory-disclosures {
    display: none;
  }
  .confirm-property-name-edit-button {
    z-index: 1001;
    background: gray;
    color: white;
    font-family: "Open Sans" sans-serif;
    border-radius: 10px;
  }
  .package-info-directory {
    display: none;
  }
}
