@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

.navbar-dashboard-button {
  background-color: transparent;

  font-family: "Outfit", sans-serif;
  font-size: 18px;
  font-weight: 275;
  letter-spacing: 0.4px;
  color: white;

  padding: 5px 15px;
  border-radius: 8px;

  cursor: pointer;
  user-select: none;
  border: 1px solid white;
  -webkit-text-stroke: 1px white;
}

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* MOBILE STYLES */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

@media screen and (max-width: 450px) {
  .navbar-dashboard-button {
    background-color: black;
    color: white;

    position: absolute;
    top: 35px;
    right: 18px;
    padding: 4px 8px;

    font-size: 17px;
    font-weight: 340;
    letter-spacing: 0.3px;
    font-family: "Open Sans", sans-serif;
    display: none;
  }
}
