/* ================================
   Page: Login
================================= */

/* ================================
     Error Message
================================= */

.auth__error-message--login {
  display: flex;
  align-items: center;
  width: 90%;
}

/* ================================
     Button
================================= */

.auth__botton--login {
  padding: 10px 65px;
}

/* ================================
     Links
================================= */

.auth__links--login {
  margin-top: 30px;
  margin-bottom: 30px;
}

/* ================================
    Mobile Styles
================================= */

@media screen and (max-width: 450px) {
  /* Error Message */

  .auth__error-message--login {
    display: flex;
    align-items: center;
    width: 85%;
    margin-bottom: 5px;
  }

  /* Button */
  .auth__controls {
    margin-top: 5px;
  }

  .auth__botton--login {
    padding: 8px 25px;
  }

  /* Links */
  .auth__links--login {
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
