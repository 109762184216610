@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

.navbar {
  display: flex;
  justify-content: space-between;
  padding: 30px 30px;
  margin-top: 40px;

  color: #ffffff;
  height: 28px;

  margin: 0px 8px;
}

.navbar-left-content {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: 20px;
}

.navbar-right-content {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.navbar-logo-white {
  height: 26px;
  margin-top: 7px;
  user-select: none;
}

.navbar-vertical-separator {
  width: 1px;
  height: 55px;
  background-color: rgba(255, 255, 255, 0.267);

  margin-left: 50px;
  margin-right: 49px;
}

.navbar-routes {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navbar-route {
  margin-right: 45px;

  font-family: "Outfit", sans-serif;
  font-weight: 200;
  font-size: 17px;
  color: white;
  letter-spacing: 0.5px;
  text-decoration: none;
  user-select: none;
}

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* MOBILE STYLES */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

.navbar-mobile-toggle {
  display: none;
}

.navbar-mobile-toggle-icon {
  height: 23px;
  width: 23px;
  position: absolute;
  z-index: 2;
  top: 41px;
  left: 32px;
  user-select: none;
}

@media screen and (max-width: 450px) {
  .navbar {
    padding: 0px 0px;
    margin-top: 25px;
    margin-bottom: 50px;
  }

  .navbar-mobile-toggle {
    margin-top: 4px;
    margin-left: 5px;
    display: block;
    cursor: pointer;
  }

  .navbar-left-content .navbar-route {
    display: none;
  }

  .navbar-vertical-separator {
    display: none;
  }

  .navbar-left-content {
    display: flex;
    top: 35px;
  }

  .navbar-left-content.active {
    display: flex;
  }

  .navbar-left-content.active .navbar-routes {
    flex-direction: column;
    background-color: white;
    position: absolute;
    top: 0px;
    left: 0px;
    padding-top: 114px;
    padding-bottom: 10px;
    width: 100%;
    height: 100%;
    z-index: 5;

    align-items: flex-start;
  }

  .navbar-left-content.active .navbar-route {
    display: block;

    font-size: 24px;
    font-weight: 300;
    color: black;

    margin-left: 32px;
    margin-bottom: 22px;
  }

  .navbar-logo-white {
    height: 34px;
    margin-top: -5px;
    user-select: none;
    position: absolute;
    left: calc(50% - 19px);
  }

  .navbar-right-content {
    margin-right: 0px;
  }
}
