@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&family=Outfit:wght@500&display=swap");

.termite-inspection-viewer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  background-color: #f8f8f8;
}

.termite-inspection-section {
  background-color: #f8f8f8;
  border-radius: 3px;
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  border: 1.5px solid black;
  width: 100%;
  box-sizing: border-box;
}

.termite-inspection-section-title {
  font-size: 16px;
  font-weight: bold;

  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  margin: -7px 0px;
}

.termite-inspection-arrow {
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease;
  margin-right: 15px;
  margin-left: 5px;
}

.termite-inspection-arrow.collapsed {
  transform: rotate(-180deg);
}

.termite-inspection-arrow.expanded {
  transform: rotate(0deg);
}

.termite-inspection-no-items {
  padding: 10px;
}

.termite-inspection-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  margin-top: 10px;
}

.termite-inspection-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
  font-family: "Outfit", sans-serif;
  transition: background-color 0.2s;
  width: 100%;
  box-sizing: border-box;
  background-color: #f8f8f8;
}

.termite-inspection-item-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.termite-inspection-item-title {
  font-weight: 600;
}

.termite-inspection-item-issue,
.termite-inspection-item-cost {
  margin-top: 5px;
}

.termite-inspection-item-severity {
  display: inline-block;
  padding: 3px 8px;
  border-radius: 4px;
  font-size: 12px;
  margin-left: 10px;
  color: white;
}

/* Severity styling */
.termite-inspection-severity-critical {
  background-color: #db2a2a;
}

.termite-inspection-severity-major {
  background-color: #e08929;
}

.termite-inspection-severity-moderate {
  background-color: #dce01f;
  color: black;
}

.termite-inspection-severity-minor {
  background-color: #b6b6b6;
}

.termite-inspection-total-cost {
  font-weight: 600;
  font-size: 18px;
  margin-top: 10px;
  font-family: "Outfit", sans-serif;
  padding: 10px;
}
