/* landing.css */

/* Ensure Outfit font is loaded */
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;600&display=swap");

/* General styles */
.landing-container {
  font-family: "Outfit", sans-serif;
  height: 100vh; /* Full height */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

/* Content Section */
.landing-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%; /* Center content vertically */
  width: 100%;
  max-width: 900px; /* Optional: Set a max-width for large screens */
  padding: 0 20px;
  margin-top: 40px;
}

/* Text and Title */
.landing-text {
  flex: 1;
  text-align: left;
  max-width: 600px;
}

/* Title Styling */
.landing-title {
  font-size: 46px; /* Large font */
  font-weight: 500;
  color: white;
}

/* Subtitle Styling */
.landing-subtitle {
  font-size: 19px; /* Smaller font */
  color: #cacaca;
  margin-top: 20px;
  font-weight: 200;
  font-family: "Open Sans";
}

/* Image Section */
.landing-image img {
  max-width: 300px; /* Adjust image size */
  width: 137px;
  height: 130px;
  height: auto;
  margin-top: -300px;
}

/* Fine Print */
.fine-print {
  margin-top: 60px;
  margin-bottom: 20px;
  padding: 10px;
  padding-bottom: 0;
  text-align: center;
  font-size: 12px;
  color: #777; /* Lighter color for fine print */
}

/* Home Graphic as Background */
.home-graphic {
  position: absolute;
  right: calc(50%);
  top: calc(10vh);
  z-index: 0;
  opacity: 0.9;
  pointer-events: none;
}
.home-graphic img {
  width: 600px; /* Set width for the image */
  height: auto;
}

/* Email Waitlist Section */
.email-waitlist {
  margin-top: 50px;
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
}

/* Form Styling */
.waitlist-form {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Email Input */
.email-input {
  font-size: 16px;
  padding: 10px;
  margin-right: 10px;
  font-family: "Open Sans";
  border: 1px solid #dfdfdf00;
  border-radius: 10px;
  width: 350px;
  padding-left: 14px;
  z-index: 1;
}
.email-input::placeholder {
  font-family: "Open Sans";
  color: rgb(168, 168, 168);
}

*:focus {
  outline: none;
}

/* Submit Button */
.join-waitlist-button {
  font-size: 16px;
  padding: 10px 20px;
  background-color: #3fa36f;
  font-family: "Open Sans";
  color: white;
  font-weight: 550;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.join-waitlist-button:hover {
  background-color: #48b779; /* Darken the button color on hover */
}

/* Modal styling */
.thank-you-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-content h2 {
  margin-bottom: 10px;
}

.modal-content p {
  margin-bottom: 20px;
}

.close-modal-btn {
  padding: 8px 16px;
  background-color: #59cb90;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.close-modal-btn:hover {
  background-color: #48b779; /* Darker shade on hover */
}

.founders-video {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.founders-video iframe {
  width: 600px;
  height: 325px;
}

/* Mobile-specific styles */
@media screen and (max-width: 750px) {
  /* Adjust text alignment and image position */
  .landing-content {
    flex-direction: column-reverse;
    margin-top: 30px;
    height: 70vh;
    max-width: 100%;
  }

  .landing-text {
    text-align: center; /* Center-align text */
    padding: 0 10px; /* Add some padding for mobile */
  }

  .landing-title {
    font-size: 32px; /* Smaller font size for mobile */
  }

  .landing-subtitle {
    font-size: 14px; /* Smaller subtitle for mobile */
    margin-top: 10px;
  }

  .landing-image img {
    max-width: 150px; /* Adjust image size */
    width: 100%;
    margin-top: 20px;
  }

  .email-waitlist {
    margin-top: 50px; /* Reduce margin for mobile */
    display: flex;
    flex-direction: column; /* Stack email input and button vertically */
    align-items: center;
    width: 100%;
  }

  .waitlist-form {
    flex-direction: column; /* Stack elements vertically */
    width: 100%;
  }

  .email-input {
    width: 300px; /* Make input take most of the screen width */
    margin-bottom: 15px; /* More space between input and button */
    text-align: center;
  }

  .home-graphic {
    position: absolute;
    top: 10%; /* Keep graphic centered */
    right: -15%;
    opacity: 0.8; /* Slightly less opacity for mobile */
  }

  .fine-print {
    font-size: 11px; /* Smaller font for fine print */
    margin-top: 10px;
  }

  .founders-video iframe {
    width: 350px;
    height: 170px;
  }

  .founders-video {
    margin-bottom: 10px;
  }
}
