.analysis-module {
  position: relative;
  height: 100%;
}

.analysis-module-analyzing {
  font-size: 27px;
  color: black;

  width: 100%;
  min-height: 100%;

  background: linear-gradient(to top, hsl(0, 0%, 91%), hsl(326, 57%, 63%));
  background-size: 50% 300%;

  animation: component-analysis-viewer-analyzing-animation 3s ease-in infinite;

  display: grid;
  place-items: center;
}
@keyframes component-analysis-viewer-analyzing-animation {
  0%,
  100% {
    background-position: 0% 0%;
  }

  50% {
    background-position: 0% 100%;
  }
}

.analysis-module-fileclassifying {
  font-size: 27px;
  color: black;

  width: 100%;
  min-height: 100%;

  background: linear-gradient(to top, hsl(0, 0%, 88%), hsl(0, 0%, 63%));
  background-size: 50% 300%;
  animation: component-analysis-viewer-fileclassifying-animation 2.2s ease-in
    infinite;

  display: grid;
  place-items: center;
}
@keyframes component-analysis-viewer-fileclassifying-animation {
  0%,
  100% {
    background-position: 0% 0%;
  }

  50% {
    background-position: 0% 100%;
  }
}

.component-analysis-viewer-error {
  color: rgb(181, 65, 65);
  margin-top: 11px;
  margin-left: 1.5px;
  font-size: 15px;
  font-weight: 500;
}

.component-analysis-viewer .chat-container {
  height: 100%;
}

.component-wrapper {
  height: 100%;
  overflow-y: visible;
  height: fit-content;
  padding: 20px 30px;
}

@media screen and (max-width: 450px) {
  .analysis-module {
    background: transparent;
    color: white;
    padding: 30px 10px;
  }
}
