@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@400;600;700&display=swap");
.disclosure-viewer-component-analysis {
  position: relative;
}

.chat-container {
  position: sticky;
  bottom: 0;
  left: 0;
  background-color: #f8f8f8;
  border-radius: 4px 4px 0 0;
  border-top-left-radius: 0;
  z-index: 4;
  overflow: hidden;

  height: 100%;
  min-width: calc(100%);
}

.chat-container.collapsed {
  height: 30px;
  margin-top: 60vh;
  z-index: 10;
  background-color: transparent;
}

.chat-container.expanded {
  margin-top: -20px;
}

.chat-toggle {
  height: 32px;
  width: 150px;
  background-color: #e5e4e4;

  margin: auto;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  font-weight: 570;
  color: black;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.chat-toggle.expanded {
  border-radius: 0px;
  border-top: none;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.chat-toggle-arrow {
  width: 10px;
  height: 10px;
  margin-right: 10px;
  content: url("../assets/SectionArrow-old.svg");
}

.chat-toggle-arrow.expanded {
  transform: rotate(180deg);
}

.chat-toggle-arrow.collapsed {
  transform: rotate(0deg);
}

.chat-view {
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px);
}

.chat-history {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.chat-message {
  margin-bottom: 10px;
  padding: 5px 9px;
  border-radius: 20px;
  font-family: "Open Sans", sans-serif;
  width: fit-content;
  height: auto;
}

.chat-message.user {
  background-color: #464c51;
  color: #f8f8f8;
  font-family: "Open Sans";
  font-size: 14px;
  max-width: 50%;
  align-self: flex-end;
}

.chat-message.system {
  color: #464c51;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  margin-right: 30%;
}

.chat-message-content {
  display: flex;
  align-items: flex-start;
}

.chat-logo {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  margin-top: 8px;
  vertical-align: middle;
  border-radius: 50%;
  border: 1px solid #b8b8b8;
  padding: 5px;
  background-color: #464c51;
}

.chat-input {
  display: flex;
}

.chat-input input {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  flex-grow: 1;
  padding: 7px 14px;
  margin: 0px 24px;
  border-radius: 12px;
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border: none;
}
.chat-input input:disabled {
  cursor: not-allowed;
}
.chat-input input:focus {
  outline: none;
  box-shadow: none;
}

.chat-reference-clickable {
  cursor: pointer;
  background-color: #4a515a;
  color: #b8b8b8;
  border-radius: 10px;
  outline: #b8b8b8;
  max-width: 80px;
  text-align: center;
}

.chat-suggestions {
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
  margin-bottom: 10px;
  margin-top: 5px;
  margin-right: 25px;
  margin-left: 25px;
}

.chat-suggestions button {
  font-family: "Open Sans", sans-serif;
  flex: 1 0 calc(30% - 10px);
  background-color: white;
  color: #717171;
  border: 1px solid #9999996b;
  border-radius: 9px;
  padding: 6px 12px;
  font-size: 13px;
  cursor: pointer;
  height: 80px;
}

.chat-suggestions button:hover {
  background-color: #f1f1f1;
}

.typing-indicator {
  display: flex;
  align-items: center;
  margin-top: 25px;
}

.typing-indicator span {
  height: 8px;
  width: 8px;
  background-color: #b8b8b8;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  animation: typing 1s infinite ease-in-out;
}

.typing-indicator span:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-indicator span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes typing {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* Dark Mode Desktop Styles */
.dark-mode .chat-container {
  background-color: #242628;
  border: none;
  border-radius: 2px 2px 0 0;
  color: rgba(255, 255, 255, 0.75);
}

.dark-mode .chat-container.collapsed {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.25);
  border: none;
}

.dark-mode .chat-container.expanded {
  background-color: #3f464d;
  color: rgba(255, 255, 255, 0.75);
  border: none;
}

.dark-mode .chat-toggle {
  border: none;
  color: rgba(255, 255, 255, 0.75);
  font-family: "Outfit", sans-serif;
  font-weight: 400;
  background-color: #24272b;
  width: 150px;
  margin-left: calc(50% - 75px);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.dark-mode .chat-toggle.expanded {
  border-radius: 0px;
  border-top: none;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.dark-mode .chat-toggle-arrow {
  content: url("../assets/SectionArrow.svg");
}

.dark-mode .chat-toggle-arrow.expanded {
  transform: rotate(180deg);
}

.dark-mode .chat-toggle-arrow.collapsed {
  transform: rotate(0deg);
}

.dark-mode .chat-history {
  background-color: #3f464d;
  color: #b8b8b8;
}

.dark-mode .chat-message.user {
  background-color: #464c51;
  color: #b8b8b8;
}

.dark-mode .chat-message.system {
  color: #b8b8b8;
}

.dark-mode .chat-input-area {
  background-color: #3f464d;
  color: #b8b8b8;
}

.dark-mode .chat-input input {
  background-color: #2f3337;
  color: #b8b8b8;
}

.dark-mode .chat-reference-clickable {
  background-color: #4a515a;
  color: #b8b8b8;
  border-radius: 10px;
  outline: #b8b8b8;
}

.dark-mode .chat-suggestions button {
  background-color: #3f464d;
  color: #b8b8b8;
  border: 1px solid #9999996b;
}

.dark-mode .chat-suggestions button:hover {
  background-color: #2f3337;
}

/* Mobile Styles */
@media screen and (max-width: 450px) {
  .chat-container {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(#242628, #4a515a);
    border: 1px solid #ccc;
    border-radius: 4px 4px 0 0;
    transition: height 0.5s ease-in-out;
    transform-origin: bottom;
    z-index: 1000;
    overflow: hidden;
  }
  .chat-container.collapsed {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-content: center;
    text-align: center;
    text-justify: center;
    height: 22px;
    width: 90px;
    position: absolute;
    bottom: 0px;
    right: calc(50vw - 45px);
    margin-left: 0;
    margin-top: 0px;
    z-index: 9999;
    font-family: "Open Sans", sans-serif;

    border-top-left-radius: 15px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 0px;

    color: rgba(255, 255, 255, 0.75);
    font-size: 15px;
    border: none;
    padding-bottom: 5px;
    background-color: #24272b;
    background-image: none;
    margin-right: 0;
  }
  .dark-mode .chat-container.collapsed {
    right: 0;
  }

  .chat-container.expanded {
    position: absolute;
    display: block;
    background-color: rgba(255, 255, 255, 0.1);
    height: 400px;
    border: none;
    bottom: 30px;
  }
  .chat-toggle {
    color: white;
    font-weight: 400;
    text-align: center;
    text-justify: center;
    height: auto;
    margin-bottom: 5px;
    margin-top: 0px;
  }
  .chat-input input {
    background-color: rgba(255, 255, 255, 0.1);
    border: none;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  .chat-toggle-arrow {
    content: url("../assets/SectionArrow.svg");
  }
  .chat-toggle-arrow.expanded {
    transform: rotate(90deg);
  }

  .chat-toggle-arrow.collapsed {
    transform: rotate(270deg);
  }
  .chat-suggestions button {
    flex: 1 0 calc(30% - 10px);
    background-color: #3f464d;
    color: #b8b8b8;
    border: 1px solid #9999996b;
    border-radius: 5px;
    padding: 6px 12px;
    font-size: 13px;
    cursor: pointer;
    height: auto;
  }
}
