@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@400;600&display=swap");

.pivot-nav {
  background-color: black;
  color: white;
  font-family: "Outfit", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px 24px;
  position: relative;
}

/* Left & Right Nav Links */
.nav-link {
  text-decoration: none;
  color: white;
  font-size: 14px;
  margin-right: 20px;
}

/* Center Logo */
.nav-logo {
  height: 40px;
  margin: 0 60px;
}

/* Dropdown */
.dropdown {
  position: relative;
}

.dropdown-button {
  background-color: transparent;
  color: white;
  padding: 8px 12px;

  font-size: 14px;
  cursor: default;
  border: none;
  transition: background 0.05s, color 0.08s;
}

.dropdown-button:hover {
  background-color: white;
  color: black;
}

.dropdown:hover .dropdown-button {
  background-color: white;
  color: black;
}

.dropdown-menu {
  position: absolute;
  left: 0;
  top: 100%;
  background-color: white;
  color: black;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 325px;
  margin-top: 0px;
  transition: opacity 0.3s, visibility 0s 0.3s;
}

.dropdown:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s, visibility 0s 0s;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu li {
  padding: 12px;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #f0f0f0;
}

.dropdown-link {
  text-decoration: none;
  color: black;
  padding: 12px;
  display: block;
}

.dropdown-link:hover {
  background-color: #f0f0f0;
}

@media screen and (max-width: 750px) {
  .dropdown-menu {
    left: 0;
    margin-left: -251.5px;
  }
}
