.analyses-nav-viewer {
  padding: 0px 15px;
  border: none;
}

.analyses-nav-viewer-category {
  margin-top: 26px;
}

.analyses-nav-viewer-category-name {
  color: rgb(156, 156, 156);
  font-size: 12px;
  margin-bottom: 8px;
}
.analyses-nav-viewer-category-name.custom-analysis {
  display: flex;
  flex-direction: row;
}
.analyses-nav-viewer-add-custom-analysis-button {
  margin-left: auto;
  margin-top: 2px;
  cursor: pointer;
  height: 11px;
  width: 11px;
}

.analyses-nav-viewer-analysis-type {
  font-size: 16px;
  padding-left: 0px;
  font-weight: 300;
  margin-bottom: 8px;
  cursor: pointer;
  letter-spacing: 0.25px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.analyses-nav-viewer-master-report {
  font-size: 16px;
  padding-left: 0px;
  font-weight: 300;
  margin-bottom: 8px;
  margin-top: 24px;
  cursor: pointer;
  letter-spacing: 0.25px;
}

.analyses-nav-viewer-master-report.selected {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(to left, #8b81e6, #ee508a, #f7abd0);
}

.analyses-nav-viewer-analysis-type.selected {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(to left, #8b81e6, #ee508a, #f7abd0);
}

.analyses-nav-viewer-analysis-type.files-not-detected,
.analyses-nav-viewer-analysis-type.error {
  color: rgb(49, 49, 49);
}

.analyses-nav-viewer-analysis-type.analyzing,
.analyses-nav-viewer-analysis-type.fileclassifying {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  animation: nav-analysis-type-fileclassifying 0.9s linear infinite alternate;
}
@keyframes nav-analysis-type-fileclassifying {
  0% {
    background-color: hsla(0, 0%, 100%, 0.11);
  }

  100% {
    background-color: rgba(255, 255, 255, 0.97);
  }
}

.analyses-nav-viewer-analysis-type.selected.analyzing,
.analyses-nav-viewer-analysis-type.analyzing:hover,
.analyses-nav-viewer-analysis-type.selected.fileclassifying,
.analyses-nav-viewer-analysis-type.fileclassifying:hover {
  animation: nav-analysis-type-selected-fileclassifying 0.9s linear infinite
    alternate;
}
@keyframes nav-analysis-type-selected-fileclassifying {
  0% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

.analyses-nav-viewer-analysis-type:hover {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(to left, #8b81e6, #ee508a, #f7abd0);
}

.analyses-nav-viewer-coming-soon {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(to left, #8b81e6, #ee508a, #f7abd0);
}

.disclosure-nav-new-custom-analysis-tag {
  color: rgb(49, 49, 49);
}

/* Mobile styles */
@media only screen and (max-width: 450px) {
  .analyses-nav-viewer-analysis-type {
    margin-right: 10px;
    font-size: 18px;
    text-justify: center;
    text-align: center;
    color: white;
  }
  .analyses-nav-viewer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.8);
  }
  .analyses-nav-viewer-category-name {
    font-size: 14px;
    text-align: center;
    text-justify: center;
  }
  .analyses-nav-viewer-category {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-justify: center;
  }
}
