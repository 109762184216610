.custom-component-title {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  overflow: hidden;
  width: 70%;
}

/* Title */
.custom-component-title-text {
  font-size: 34px;
  font-family: "Outfit", sans-serif;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
}
.custom-component-title-text:hover {
  cursor: pointer;
}
.custom-component-title-text input {
  font-size: inherit;
  font-weight: inherit;
  width: 100%;
  font-family: "Outfit", sans-serif;
  border: none;
  background: transparent;
  outline: none;
  padding: 0;
}
/* Title text edit button */
.custom-component-title-text-edit {
  vertical-align: bottom;
  height: 12px;
  width: 12px;
}
.custom-component-title-text-edit:hover {
  cursor: pointer;
}

/* File associater */
.custom-component-files-associator {
  width: 425px;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 20px;
}
/* Toggle button */
.custom-component-files-associator-toggle-button {
  background-color: black;
  border: none;
  cursor: pointer;

  padding: 6px 6.5px;
  border-radius: 3px;
  text-align: left;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
.custom-component-files-associator-toggle-button.open {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.custom-component-files-associator-toggle-button-text {
  color: white;
  font-size: 12.5px;
  font-family: "Outfit", sans-serif;
  font-weight: 420;
  letter-spacing: 0.45px;
}
/* Toggle arrow */
.custom-component-files-associator-toggle-arrow {
  margin-right: 2px;
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}
.custom-component-files-associator-toggle-arrow.open {
  transform: rotate(-180deg);
}
/* Toggle dropdown list */
.custom-component-files-associator-dropdown {
  max-height: 0;
  overflow: hidden;
  border-top: none;
  border: 1px solid black;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-top: -5px;
}
.custom-component-files-associator-dropdown.open {
  max-height: 300px;
  border-top: none;

  margin-top: -4px;
}
/* Toggle dropdown list item */
.custom-component-files-associator-item {
  padding: 5px 0px;
  font-size: 13px;
  font-weight: 325;
  align-items: center;

  margin-right: 8px;
  margin-left: 6px;

  white-space: nowrap;
  overflow-x: auto;

  /* Hide scrollbar */
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.custom-component-files-associator-item:first-child {
  margin-top: 4px;
}
.custom-component-files-associator-item-divider {
  border-bottom: 1px solid black;
}
.custom-component-files-associator-item-divider:last-child {
  border-bottom: none;
}
.custom-component-files-associator-item-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
}
.custom-component-files-associator-add {
  width: 15px;
  height: 15px;
  fill: green;
}
.custom-component-files-associator-remove {
  width: 15px;
  height: 15px;
  fill: red;
}
.custom-component-files-associator-confirm {
  color: black;
  background-color: white;
  border-radius: 4px;
  border: none;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  width: max-content;
  font-family: "Outfit", sans-serif;
  padding: 0px 5px;
}
.custom-component-files-associator-filename {
  width: 90%;
  overflow-x: hidden;
}

/* Prompt editor */
.custom-component-prompt-editor {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.custom-component-prompt-editor input {
  width: 60%;
  border-radius: 5px;
  border: 1px solid black;
}
.custom-component-prompt-editor input::placeholder {
  color: grey;
  font-style: italic;
}
.custom-component-prompt-editor-placeholder {
  color: grey;
  font-style: italic;
  font-family: "Outfit", sans-serif;
}
.custom-component-prompt-editor-text-input {
  font-family: "Outfit", sans-serif;
}
/* Analysis Viewer */
.custom-component-analysis {
  margin-top: 30px;
}

/* Submit Button*/
.custom-component-analysis-submit-button {
  color: BLACK;
  border: none;
  border-radius: 2px;
  font-size: 12.5px;
  font-family: "Outfit", sans-serif;
  font-weight: 420;
  letter-spacing: 0.45px;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .custom-component-title-text {
    width: 100vw;
  }
  .custom-component-title {
    width: 90%;
  }
  .custom-component-files-associator-toggle-button {
    width: 75%;
  }
  /* Toggle dropdown list */
  .custom-component-files-associator-dropdown {
    width: 70%;
  }
}
