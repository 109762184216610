.nav-files-viewer {
  padding: 15px 15px;
}

.nav-files-viewer-filename {
  margin-bottom: 10px;

  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.35px;

  white-space: nowrap;
  overflow-x: auto;
  scrollbar-width: none; /* Hide scrollbar in Firefox */
  -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
}
/* Hide scrollbar in WebKit-based browsers */
.nav-files-viewer-filename::-webkit-scrollbar {
  display: none;
}

/* Shimmy for when loading */
@keyframes nav-files-viewer-shimmy {
  0% {
    background-color: hsl(180, 0%, 44%);
  }

  100% {
    background-color: hsl(0, 0%, 20%);
  }
}
.nav-files-viewer-filename-shimmy {
  margin-top: 4px;
}
.nav-files-viewer-filename-shimmy-filename {
  width: 155px;
  margin-bottom: 11px;
  height: 13px;
  border-radius: 3px;
  opacity: 0.6;

  animation: nav-files-viewer-shimmy 0.8s linear infinite alternate;
}
