.hoa-rental-restrictions-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.hoa-rental-restrictions-item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  font-weight: 400;
  font-size: 16px;
  transition: background-color 0.2s;
}
.hoa-rental-restrictions-item:last-child {
  border-bottom: none;
}
.hoa-rental-restrictions-item:hover {
  transition: background-color 0.25s ease;
  background-color: rgba(0, 0, 0, 0.044);
  border-radius: 4px;
  cursor: pointer;
}
