.footer{
    background-color: #181C21;
    border-radius: 75px 75px 0 0;     
    padding: 20px 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    font-family: 'Open Sans', sans-serif;
    margin-top: 50px;
}
.footer-content{
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}
.footer-mobile{
    display: none;
}
/* ////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////// */
/* footer links */
.footer-column {
    flex: 1;
    margin: 20px;
}
.footer-column img {
    width: 40px;
    height: auto;
}
.footer-column ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.footer-subtitle {
    font-weight: bold;
    color: white;
    margin-bottom: 10px;
    font-size: 14px;
}
.footer-text {
    margin-bottom: 8px;
}
.footer-text a {
    text-decoration: none;
    color: #9F9F9F;
    font-size: 14px;
}
.footer-text a:hover {
    text-decoration: underline;
}
/* ////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////// */

/* ////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////// */
/* footer socials */
.footer-socials{
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 20px;
}
.footer-socials a img {
    width: 20px;
    height: auto;
}
/* ////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////// */

/* ////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////// */
/* footer disclaimers */
.footer-disclaimer {
    display: flex;
    justify-content: center;
    text-align: center;
}
.footer-disclaimer,
.footer-copyright {
    color: #ffffff80;
    flex-basis: 100%; 
    text-align: center; 
    margin-top: 15px; 
    font-size: 14px;
}
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* MOBILE STYLES */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 450px) {
    .footer{
        display: none;
    }
    .footer-mobile{
        background-color: #181C21;
        border-radius: 40px 40px 0 0;
        padding: 20px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: white;
        margin-top: 80px;
    }
    /* ////////////////////////////////////////////////////////////////////////////////////// */
    /* ////////////////////////////////////////////////////////////////////////////////////// */

    /* ////////////////////////////////////////////////////////////////////////////////////// */
    /* ////////////////////////////////////////////////////////////////////////////////////// */
    /* footer mobile images */
    .footer-mobile-imgs{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;
    } 
    .footer-mobile-logo img {
        width: 24px;
        height: auto;
        padding-left: 15px;
    }
    .footer-mobile-socials {
        display: flex;
        align-items: center;
        gap: 20px;
        padding-right: 15px;
    }
    .footer-mobile-socials img {
        width: 16px;
        height: auto;
    }
    /* ////////////////////////////////////////////////////////////////////////////////////// */
    /* ////////////////////////////////////////////////////////////////////////////////////// */

    /* ////////////////////////////////////////////////////////////////////////////////////// */
    /* ////////////////////////////////////////////////////////////////////////////////////// */
    /* footer mobile content */
    .footer-mobile-body {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-right: 30px;
    }   
    .footer-mobile-column {
        display: flex;
        flex-direction: column;
        margin: 0px 20px
    }
    .footer-mobile-column ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .footer-mobile-subtitle,
    .footer-mobile-text {
        font-size: 12px; 
        font-weight: bold;
        margin-bottom: 14px;
    }
    .footer-mobile-subtitle {
        color: white;
    }
    
    .footer-mobile-text a {
        text-decoration: none;
        color: #9F9F9F;
    }
    /* ////////////////////////////////////////////////////////////////////////////////////// */
    /* ////////////////////////////////////////////////////////////////////////////////////// */

    /* ////////////////////////////////////////////////////////////////////////////////////// */
    /* ////////////////////////////////////////////////////////////////////////////////////// */
    /* footer mobile disclaimer */
    .footer-mobile-disclaimer,
    .footer-mobile-copyright {
        font-size: 10px;
        margin-bottom: 10px;
        color: #ffffff80;
    }
}