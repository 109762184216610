@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Outfit:wght@100..900&display=swap");

.pdf-viewer {
  max-height: calc(100% - 10px);

  margin-top: -26px;
  margin-top: -26px;
  margin-left: 10px;
}

.pdf-viewer.no-files {
  background-color: rgb(217, 217, 217);
  margin-top: 0px;
  border-radius: 1.3vh;

  min-height: 100%;
  min-width: 53vh;
  max-width: 53vh;

  font-weight: 450;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dark-mode .pdf-viewer.no-files {
  color: black;
  background-color: rgb(172, 172, 172);
}

/* PDF */
.pdf-viewer-content {
  display: flex;
}

.pdf-viewer-container {
  position: relative;
}
.pdf-viewer-container.no-files {
  height: 10vh;
  background-color: blue;
}

.pdf-viewer-container-page {
  padding: 2px;
  border: 0.2vh solid black;
  border-radius: 1.3vh;
  border-top-right-radius: 0vh;
}

.react-pdf__Page__canvas {
  margin: 0.3vh;
  max-height: calc(100% - 10px);
}
.react-pdf__Document {
  width: fit-content;
}

/* Page Controls */
.pdf-viewer-page-controls {
  margin-left: 0px;
}
.pdf-viewer-page-controls-page-number-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  width: 34px;
  background-color: black;

  border-top: 0.2vh solid black;
  border-right: 0.2vh solid black;

  border-radius: 5px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.pdf-viewer-page-controls-page-number {
  color: white;
  text-align: center;
  user-select: none;
}
.pdf-viewer-page-controls-page-up-wrapper {
  height: 34px;
  width: 34px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-right: 0.2vh solid black;
  border-bottom: 0.2vh solid black;
}
.pdf-viewer-page-controls-page-down-wrapper {
  height: 34px;
  width: 34px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-right: 0.2vh solid black;
  border-bottom: 0.2vh solid black;
  border-bottom-right-radius: 1.3vh;
}
.pdf-viewer-page-controls-page-down-wrapper img svg path {
  fill: red;
}
.pdf-viewer-page-controls-page-up-wrapper.disabled:hover,
.pdf-viewer-page-controls-page-down-wrapper.disabled:hover {
  cursor: default;
}
.pdf-viewer-page-controls-page-down-wrapper:hover,
.pdf-viewer-page-controls-page-up-wrapper:hover {
  cursor: pointer;
}

.pdf-viewer-page-controls-page-up {
  user-select: none;
  margin-right: -2px;
  margin-bottom: -5px;
}
.pdf-viewer-page-controls-page-down {
  margin-top: -4px;
  transform: rotate(180deg);
  user-select: none;
}
.pdf-viewer-page-controls-page-up.disabled {
  cursor: pointer;
}
.pdf-viewer-page-controls-page-down.disabled {
  transform: rotate(180deg);
}

/* Filenames */
.pdf-viewer-filenames-container {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 8px;

  transition: transform 0.8s ease-out;
  overflow: hidden;
}

.pdf-viewer-filename-current {
  flex: 1.7;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 25px;
  padding-right: 25px;
  overflow: hidden;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(
    to right,
    #000000,
    #000000,
    #000000,
    #000000c3,
    #00000000
  );
}
.dark-mode .pdf-viewer-filename-current {
  font-weight: 300;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(
    to right,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffffe6,
    #ffffff00
  );
}
.pdf-viewer-filename-previous {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  direction: rtl;
  text-align: left;
  z-index: 0;

  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(to left, #000000, #00000050, #00000000);
}
.dark-mode .pdf-viewer-filename-previous {
  background-image: linear-gradient(to left, #ffffff, #ffffff50, #ffffff00);
  font-weight: 300;
}
.pdf-viewer-filename-next {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  direction: ltr;
  text-align: left;

  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(to right, #000000, #00000050, #00000000);
}
.dark-mode .pdf-viewer-filename-next {
  background-image: linear-gradient(to right, #ffffff, #ffffff50, #ffffff00);
  font-weight: 300;
}
.pdf-viewer-filename-previous:hover,
.pdf-viewer-filename-next:hover {
  cursor: pointer;
}
.pdf-viewer-filename-previous.disabled:hover {
  cursor: auto;
}
.pdf-viewer-filename-next.disabled:hover {
  cursor: auto;
  color: rgba(255, 0, 0, 0.139);
}

.page-arrow-icon {
  fill: black;
}
.dark-mode .page-arrow-icon {
  fill: rgb(178, 178, 178);
}

.page-arrow-icon-grey {
  fill: rgb(196, 196, 196);
}
.dark-mode .page-arrow-icon-grey {
  fill: rgb(31, 31, 31);
}

/* Mobile styles */
@media only screen and (max-width: 450px) {
  .pdf-viewer {
    margin-top: 40px;
    color: white;
    border-radius: 12px;

    min-height: 500px; /* Minimum height */

    max-width: calc(100% - 5px); /* Max width with padding/margin */
    min-width: calc(100% - 5px);
    width: calc(100% - 20px); /* Ensure it scales down responsively */

    height: auto; /* Allow height to grow as needed */

    align-self: center;

    background-color: rgba(255, 255, 255, 0.1);
    padding: 1px; /* Add padding to make space for the border */

    background-clip: padding-box; /* Ensure the background stops at the border */
    position: relative;

    /* Create the gradient border using a pseudo-element */
  }

  .pdf-viewer:before {
    content: "";
    position: absolute;
    top: -3px; /* Adjust based on border thickness */
    left: -3px;
    right: -3px;
    bottom: -3px;
    z-index: -1;
    border-radius: 12px; /* Match the border-radius of the container */
    background: linear-gradient(to bottom, #4a6177, #ffffff); /* Gradient */
  }

  .pdf-viewer-content {
    display: flex;
    justify-content: center;
  }

  .pdf-viewer-container-page {
    border-top-right-radius: 1.3vh;
  }

  .react-pdf__Page {
    width: fit-content;
  }
  .react-pdf__Page__canvas {
    width: fit-content;
  }

  /* Page Controls */
  .pdf-viewer-page-controls {
    display: none; /* REMOVE THIS ONCE WE HAVE AN IDEA HOW TO SWITCH PAGES */
  }
}
