.share-disclosure-analysis-button {
  margin-top: 12px;
  margin-right: 20px;
  padding: 7px 6px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 5px;
  height: 23px;
  width: 80px;

  background-color: black;
  color: white;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.4px;

  text-align: center;

  position: relative;
  display: inline-block;
  cursor: pointer;
}
.dark-mode .share-disclosure-analysis-button {
  background-color: white;
  color: black;
  font-weight: 400;
}

.share-disclosure-analysis-button-coming-not {
  visibility: hidden;
  transition: opacity 0.3s;
  opacity: 0;
}

.share-disclosure-analysis-button-coming-copied {
  visibility: visible;
  font-size: 14px;
  background-color: rgb(238, 0, 123);
  color: white;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 1;
  transition: opacity 0.3s;
}

.share-disclosure-analysis-button-coming-soon-copied::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgb(238, 0, 0) transparent transparent transparent;
}

.share-disclosure-analysis-button-spinner {
  flex: 1;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid black;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  margin-left: calc(40px - 10px);
  margin-top: 1px;
  animation: share-disclosure-analysis-button-spin 1s linear infinite;
}

@keyframes share-disclosure-analysis-button-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 450px) {
  .share-disclosure-analysis-button {
    color: transparent;
    background-image: url("../../../../../assets/share-icon-mobile.svg");
    width: 10px;
    height: 15px;
    border-radius: 0px;
    margin-left: -30px;
    background-color: transparent;
    margin-right: 20px;
    margin-top: 0px;
    background-size: contain; /* Ensure the SVG scales to fit within the button */
    background-repeat: no-repeat; /* Prevent the background from repeating */
    background-position: center; /* Center the SVG within the button */
  }

  .dark-mode .share-disclosure-analysis-button {
    background-color: black;
    color: transparent;
    font-weight: 400;
  }
}
