.disclosure-viewer {
  background-color: white;
  padding: 32px 40px;
  padding-bottom: 0.1px;
  height: calc(100% + 32px);
  font-family: "Outfit", sans-serif;
}
.disclosure-viewer-selected-section {
  display: none;
}
.disclosure-viewer-footer {
  display: block;
  font-family: "Open Sans", sans-serif;
  font-size: 11.5px;
  font-weight: 400;
  margin-left: 100px;
  padding-top: 6.5px;
}

.disclosure-viewer-header {
  display: flex;
  justify-content: space-between;
}

.disclosure-viewer-viewers {
  display: flex;
  max-width: max(66vw, 800px);
  margin-top: 25px;
  height: calc(100vh - 265px);
  min-width: 100%;
  justify-content: flex-end;
}

.disclosure-viewer-navigation {
  border: 1px solid black;
  min-width: 220px;
  max-width: 220px;
  background-color: black;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 6px;
  color: white;
  overflow-y: auto;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
}

.disclosure-viewer-viewers-analysis {
  display: flex;
  flex-grow: 1;
}

.disclosure-viewer-component-analysis {
  overflow-y: auto;
  position: relative;
  max-width: calc(100% - 200px);
  flex-grow: 1;
  background-color: #f8f8f8;
  scrollbar-width: none;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

/* DARK MODE STYLING */
/* Base styles (already existing styles) remain the same */

/* Dark Mode Styles */
.dark-mode .disclosure-viewer {
  background: linear-gradient(to bottom, #000000 9%, #293139 65%);
  color: #b8b8b8;
}

.dark-mode .disclosure-viewer-header {
  background-color: transparent;
  color: white;
}

.dark-mode .disclosure-viewer-navigation {
  background-color: rgba(0, 0, 0, 0.25);
  border: none;
  color: #fff;
}

.dark-mode .disclosure-viewer-viewers {
  background-color: transparent;
}

.dark-mode .disclosure-viewer-viewers-analysis {
  background-color: transparent;
  color: white;
}

.dark-mode .disclosure-viewer-component-analysis {
  background-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .disclosure-viewer-footer {
  color: #b8b8b8;
}

/* Scrollbar hidden styles (for dark mode) */
.dark-mode ::-webkit-scrollbar {
  display: none;
}

/* Dark Mode Toggle */

.dark-mode-toggle {
  position: absolute;
  display: inline-block;
  width: 50px;
  height: 28px;
  left: max(calc(66vw - 5px), 795px); /* Adjust as needed */

  margin-top: 20px;
}

.dark-mode-toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.dark-mode-toggle .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("../../../../assets/dark-mode-toggle-light.svg");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 34px;
  transition: background 0.4s;
}

.dark-mode-toggle .slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 3px;
  bottom: 3px;
  background: url("../../../../assets/sun-icon.svg");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  transition: transform 0.4s;
}

.dark-mode-toggle input:checked + .slider {
  background: url("../../../../assets/dark-mode-toggle.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.dark-mode-toggle input:checked + .slider:before {
  transform: translateX(22px);
  background: url("../../../../assets/moon-icon.svg");
}

/* Mobile styles */
@media only screen and (max-width: 450px) {
  .disclosure-viewer {
    padding: 0px 0px; /* Reduced padding for smaller screens */
    background: linear-gradient(180deg, #171c20, #293139);
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    overflow-x: hidden;
  }

  .disclosure-viewer-header {
    flex-direction: row;
    align-items: center; /* Align items vertically */
    width: 100%;
    margin-right: 1px;
    justify-items: center;
    text-align: center;
    text-justify: center;
  }

  .disclosure-viewer-viewers {
    flex-direction: column;
    height: auto; /* Adjust height for mobile */
    margin-top: 15px;
  }

  .disclosure-viewer-navigation {
    min-width: 100%;

    border-top-right-radius: 20px; /* Adjust border radius for mobile */
    border-top-left-radius: 20px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .disclosure-viewer-navigation.collapsed {
    height: 30px; /* Small strip for the current section */
    overflow: hidden;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.4); /* Color for the collapsed state */
  }

  .disclosure-viewer-navigation.expanded {
    height: auto;
  }

  .disclosure-viewer-selected-section {
    display: block;
    padding: 10px;
    font-size: 13px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.4); /* Color for the expanded state */
    color: #fff;
    cursor: pointer;
    /* border-bottom: 1px solid #888; */
    font-family: "Outfit" sans-serif;
  }
  .disclosure-viewer-selected-section {
    background: linear-gradient(to right, white, white, #ff3880, #827ded);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .disclosure-viewer-viewers-analysis {
    flex: 1;
    margin-right: 0;
    min-width: auto; /* Remove min-width restriction */
    width: 100%;
  }

  .disclosure-viewer-component-analysis {
    overflow-y: visible;
    max-width: 100%;
    width: 100%;
    height: 100%;
    margin-top: 0;
    background-color: rgba(255, 255, 255, 0.1);
  }

  .disclosure-viewer-viewers-analysis {
    flex-direction: column;
  }
  .disclosure-viewer-footer {
    color: #b8b8b8d0;
    font-size: 8px;
    text-justify: center;
    text-align: center;
    padding: 5px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    border-top: 1px solid #8879ec2b;
    margin: 0px;
    background-color: rgba(255, 255, 255, 0.1);
    display: block;
  }
  .dark-mode-toggle {
    display: none;
  }
}
